export const TABLE_TH = [
    {
        name: 'ID',
        sort: '',
        noSort: true,
        key: 'id',
        description: ''
    },
    {
        name: 'Name',
        sort: '',
        noSort: false,
        key: 'name',
        description: ''
    },
    {
        name: 'Discount',
        sort: '',
        noSort: false,
        key: 'discount',
        description: ''
    },
    {
        name: 'Status',
        sort: '',
        noSort: false,
        key: 'status',
        description: ''
    },
    {
        name: 'Edit',
        sort: '',
        noSort: false,
        key: 'edit',
        description: ''
    },
];

import service from "@/plugins/request";

class TradeService {

    /**
     * @param idAcc
     * @returns {Promise<AxiosResponse<any>>}
     */
    getBalanceAcc(idAcc, query) {
        return service.get(`/api/multiEchenge/${idAcc}/balance` + query)
    }

    /**
     * @param idAcc
     * @returns {Promise<AxiosResponse<any>>}
     */
    getListExchange(idAcc) {
        return service.get(`/api/multiEchenge/${idAcc}/listExchenge`)
    }

    /**
     * @param idAcc
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    createExchange(idAcc, query) {
        return service.post(`/api/multiEchenge/${idAcc}/createExchenge`, query)
    }

    /**
     * @param idAcc
     * @param page
     * @returns {Promise<AxiosResponse<any>>}
     */
    getListWithdraw(idAcc, page) {
        return service.get(`/api/multiEchenge/${idAcc}/listWithdraw` + page)
    }

    /**
     * @param idAcc
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    createWithdraw(idAcc, query) {
        return service.post(`/api/multiEchenge/${idAcc}/createWithdraw`, query)
    }

    /**
     * @param idAcc
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    createTransfer(idAcc, query) {
        return service.get(`/api/multiEchenge/${idAcc}/transferAmount` + query)
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    collectProfit(query) {
        return service.get('/api/multiEchenge/calcProfit' + query)
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAccountsList() {
        return service.get('/api/multiEchenge/listAllAccounts')
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateAccountStatus(query) {
        return service.patch('/api/multiEchenge/updateAccount' + query)
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    createTradeAccount(query) {
        return service.post('/api/multiEchenge/createSubAccount', query)
    }

    cancelTransaction(id) {
        return service.get('/api/multiEchenge/cancelTransaction' + id)
    }

    getDepositCoins() {
        return service.get('/api/coin/okxCoins')
    }

    getDepositAddress(query) {
        return service.get('/api/multiEchenge/getDepositAddress' + query)
    }

    submitKyc(id, query) {
        return service.post(`/api/multiEchenge/${id}/SubmitKYC`, query)
    }
}
export default new TradeService()

<template>
  <div
    class="kyc-popup"
  >
    <div class="kyc-popup_modal">
      <div
        class="close"
        @click="$emit('closeKyc')"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4825_285)">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#527AA1"
            />
          </g>
          <defs>
            <clipPath id="clip0_4825_285">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="left-block">
        <div class="title">
          KYC Details
        </div>

        <div class="inputs-wrapper">
<!--          <div class="horizontal-wrapper">
            <div
              class="inputs&#45;&#45;item inputs&#45;&#45;item_amount"
              :class="{'focus': !!clientUid, 'error': clientUidError}"
              @click="focusOn('clientUid')"
            >
              <div class="input">
                <div class="desc">
                  clientUid
                </div>
                <input
                  ref="clientUid"
                  v-model="clientUid"
                  type="text"
                  @keyup="clientUidError = false"
                >
              </div>
            </div>

            <div class="inputs">
              <div
                ref="selectIssueCountry"
                class="type"
              >
                <div
                  class="select-input"
                  @click="isIssueCountry = !isIssueCountry"
                >
                  <div class="selected">
                    <div class="desc">
                      Issue country
                    </div>
                    <div class="value">
                      {{ selectedIssueCountry }}
                    </div>
                  </div>

                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_6222)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_6222">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  v-if="isIssueCountry"
                  class="select-input_dropmenu"
                >
                  <div
                    v-for="(type, index) in switchersIssueCountry"
                    :key="index"
                    class="item"
                    @click="selectedIssueCountry = type; isIssueCountry = !isIssueCountry"
                  >
                    {{ type }}

                    <svg
                      v-if="type === selectedIssueCountry"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3860_8139)">
                        <path
                          d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                          fill="#007AFF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3860_8139">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>-->

          <div
            class="inputs--item inputs--item_amount"
            :class="{'focus': !!issueCountry, 'error': issueCountryError}"
            @click="focusOn('issueCountry')"
          >
            <div class="input">
              <div class="desc">
                Issue country
              </div>
              <input
                ref="issueCountry"
                v-model="issueCountry"
                type="text"
                @keyup="issueCountryError = false"
              >
            </div>
          </div>

          <div
            class="inputs--item inputs--item_amount"
            :class="{'focus': !!firstName, 'error': firstNameError}"
            @click="focusOn('firstName')"
          >
            <div class="input">
              <div class="desc">
                First name
              </div>
              <input
                ref="firstName"
                v-model="firstName"
                type="text"
                @keyup="firstNameError = false"
              >
            </div>
          </div>

          <div
            class="inputs--item inputs--item_amount"
            :class="{'focus': !!lastName, 'error': lastNameError}"
            @click="focusOn('lastName')"
          >
            <div class="input">
              <div class="desc">
                Last name
              </div>
              <input
                ref="lastName"
                v-model="lastName"
                type="text"
                @keyup="lastNameError = false"
              >
            </div>
          </div>

          <div class="horizontal-wrapper">
            <div
              class="inputs--item inputs--item_amount"
              :class="{'focus': !!birthDay, 'error': birthDayError}"
              @click="focusOn('birthDay')"
            >
              <div class="input">
                <div class="desc">
                  Birth day
                </div>
                <input
                  ref="birthDay"
                  v-model="birthDay"
                  type="text"
                  @keyup="birthDayError = false"
                >
              </div>
            </div>

            <div
              class="inputs--item inputs--item_amount"
              :class="{'focus': !!expireDay, 'error': expireDayError}"
              @click="focusOn('expireDay')"
            >
              <div class="input">
                <div class="desc">
                  Expire day
                </div>
                <input
                  ref="expireDay"
                  v-model="expireDay"
                  type="text"
                  @keyup="expireDayError = false"
                >
              </div>
            </div>
          </div>

          <div class="horizontal-wrapper">
            <div class="inputs">
              <div
                ref="selectIdentityType"
                class="type"
              >
                <div
                  class="select-input"
                  @click="isIdentityType = !isIdentityType"
                >
                  <div class="selected">
                    <div class="desc">
                      Identity type
                    </div>
                    <div class="value">
                      {{ selectedIdentityType }}
                    </div>
                  </div>

                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_6222)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_6222">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  v-if="isIdentityType"
                  class="select-input_dropmenu"
                >
                  <div
                    v-for="(type, index) in switchersIdentityType"
                    :key="index"
                    class="item"
                    @click="selectedIdentityType = type; isIdentityType = !isIdentityType"
                  >
                    {{ type }}

                    <svg
                      v-if="type === selectedIdentityType"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3860_8139)">
                        <path
                          d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                          fill="#007AFF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3860_8139">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="inputs--item inputs--item_amount"
              :class="{'focus': !!identityNumber, 'error': identityNumberError}"
              @click="focusOn('identityNumber')"
            >
              <div class="input">
                <div class="desc">
                  Identity Number
                </div>
                <input
                  ref="identityNumber"
                  v-model="identityNumber"
                  type="text"
                  @keyup="identityNumberError = false"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-block">
        <div class="title">
          Scans
        </div>

        <div class="scans-block">
          <div class="scans-block-wrapper">
            <div class="scans-block-item">
              <span class="title">Face Photo</span>

              <div
                v-if="!facePhotoImageInline"
                class="image-upload"
                :class="{error: facePhotoImageError}"
              >
                <input
                  class="file-input"
                  type="file"
                  accept="image/*"
                  @change="event => onPhotoChange(event,'facePhoto')"
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M2.4987 17.4999C2.04036 17.4999 1.648 17.3367 1.32161 17.0103C0.995226 16.6839 0.832031 16.2916 0.832031 15.8333V5.83325C0.832031 5.37492 0.995226 4.98256 1.32161 4.65617C1.648 4.32978 2.04036 4.16658 2.4987 4.16658H5.1237L6.66536 2.49992H11.6654V4.16658H7.39453L5.8737 5.83325H2.4987V15.8333H15.832V8.33325H17.4987V15.8333C17.4987 16.2916 17.3355 16.6839 17.0091 17.0103C16.6827 17.3367 16.2904 17.4999 15.832 17.4999H2.4987ZM15.832 5.83325V4.16658H14.1654V2.49992H15.832V0.833252H17.4987V2.49992H19.1654V4.16658H17.4987V5.83325H15.832ZM9.16537 14.5833C10.207 14.5833 11.0924 14.2187 11.8216 13.4895C12.5508 12.7603 12.9154 11.8749 12.9154 10.8333C12.9154 9.79158 12.5508 8.90617 11.8216 8.177C11.0924 7.44783 10.207 7.08325 9.16537 7.08325C8.1237 7.08325 7.23828 7.44783 6.50911 8.177C5.77995 8.90617 5.41536 9.79158 5.41536 10.8333C5.41536 11.8749 5.77995 12.7603 6.50911 13.4895C7.23828 14.2187 8.1237 14.5833 9.16537 14.5833ZM9.16537 12.9166C8.58203 12.9166 8.08898 12.7152 7.6862 12.3124C7.28342 11.9096 7.08203 11.4166 7.08203 10.8333C7.08203 10.2499 7.28342 9.75686 7.6862 9.35408C8.08898 8.95131 8.58203 8.74992 9.16537 8.74992C9.7487 8.74992 10.2418 8.95131 10.6445 9.35408C11.0473 9.75686 11.2487 10.2499 11.2487 10.8333C11.2487 11.4166 11.0473 11.9096 10.6445 12.3124C10.2418 12.7152 9.7487 12.9166 9.16537 12.9166Z"
                    fill="#527AA1"
                  />
                </svg>
              </div>

              <div
                v-else
                class="selected-photo"
              >
                <img
                  :src="facePhotoImageInline"
                  alt="selected photo"
                >

                <div class="buttons">
                  <button @click="zoomPhotoHandler('facePhoto')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.9987 13.3333C11.0404 13.3333 11.9258 12.9687 12.6549 12.2395C13.3841 11.5103 13.7487 10.6249 13.7487 9.58325C13.7487 8.54159 13.3841 7.65617 12.6549 6.927C11.9258 6.19784 11.0404 5.83325 9.9987 5.83325C8.95703 5.83325 8.07161 6.19784 7.34245 6.927C6.61328 7.65617 6.2487 8.54159 6.2487 9.58325C6.2487 10.6249 6.61328 11.5103 7.34245 12.2395C8.07161 12.9687 8.95703 13.3333 9.9987 13.3333ZM9.9987 11.8333C9.3737 11.8333 8.84245 11.6145 8.40495 11.177C7.96745 10.7395 7.7487 10.2083 7.7487 9.58325C7.7487 8.95825 7.96745 8.427 8.40495 7.9895C8.84245 7.552 9.3737 7.33325 9.9987 7.33325C10.6237 7.33325 11.1549 7.552 11.5924 7.9895C12.0299 8.427 12.2487 8.95825 12.2487 9.58325C12.2487 10.2083 12.0299 10.7395 11.5924 11.177C11.1549 11.6145 10.6237 11.8333 9.9987 11.8333ZM9.9987 15.8333C7.97092 15.8333 6.1237 15.2673 4.45703 14.1353C2.79036 13.0034 1.58203 11.486 0.832031 9.58325C1.58203 7.68047 2.79036 6.16311 4.45703 5.03117C6.1237 3.89922 7.97092 3.33325 9.9987 3.33325C12.0265 3.33325 13.8737 3.89922 15.5404 5.03117C17.207 6.16311 18.4154 7.68047 19.1654 9.58325C18.4154 11.486 17.207 13.0034 15.5404 14.1353C13.8737 15.2673 12.0265 15.8333 9.9987 15.8333ZM9.9987 14.1666C11.5681 14.1666 13.0091 13.7534 14.3216 12.927C15.6341 12.1006 16.6376 10.986 17.332 9.58325C16.6376 8.18047 15.6341 7.06589 14.3216 6.2395C13.0091 5.41311 11.5681 4.99992 9.9987 4.99992C8.42925 4.99992 6.98828 5.41311 5.67578 6.2395C4.36328 7.06589 3.35981 8.18047 2.66536 9.58325C3.35981 10.986 4.36328 12.1006 5.67578 12.927C6.98828 13.7534 8.42925 14.1666 9.9987 14.1666Z"
                        fill="white"
                      />
                    </svg>
                  </button>

                  <button @click="deletePhotoHandler('facePhoto')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5.83203 17.5C5.3737 17.5 4.98134 17.3368 4.65495 17.0104C4.32856 16.684 4.16536 16.2917 4.16536 15.8333V5H3.33203V3.33333H7.4987V2.5H12.4987V3.33333H16.6654V5H15.832V15.8333C15.832 16.2917 15.6688 16.684 15.3424 17.0104C15.0161 17.3368 14.6237 17.5 14.1654 17.5H5.83203ZM14.1654 5H5.83203V15.8333H14.1654V5ZM7.4987 14.1667H9.16536V6.66667H7.4987V14.1667ZM10.832 14.1667H12.4987V6.66667H10.832V14.1667Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="scans-block-item">
              <span class="title">Front Photo</span>

              <div
                v-if="!frontPhotoImageInline"
                class="image-upload"
                :class="{error: frontPhotoImageError}"
              >
                <input
                  class="file-input"
                  type="file"
                  accept="image/*"
                  @change="event => onPhotoChange(event,'frontPhoto')"
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M2.4987 17.4999C2.04036 17.4999 1.648 17.3367 1.32161 17.0103C0.995226 16.6839 0.832031 16.2916 0.832031 15.8333V5.83325C0.832031 5.37492 0.995226 4.98256 1.32161 4.65617C1.648 4.32978 2.04036 4.16658 2.4987 4.16658H5.1237L6.66536 2.49992H11.6654V4.16658H7.39453L5.8737 5.83325H2.4987V15.8333H15.832V8.33325H17.4987V15.8333C17.4987 16.2916 17.3355 16.6839 17.0091 17.0103C16.6827 17.3367 16.2904 17.4999 15.832 17.4999H2.4987ZM15.832 5.83325V4.16658H14.1654V2.49992H15.832V0.833252H17.4987V2.49992H19.1654V4.16658H17.4987V5.83325H15.832ZM9.16537 14.5833C10.207 14.5833 11.0924 14.2187 11.8216 13.4895C12.5508 12.7603 12.9154 11.8749 12.9154 10.8333C12.9154 9.79158 12.5508 8.90617 11.8216 8.177C11.0924 7.44783 10.207 7.08325 9.16537 7.08325C8.1237 7.08325 7.23828 7.44783 6.50911 8.177C5.77995 8.90617 5.41536 9.79158 5.41536 10.8333C5.41536 11.8749 5.77995 12.7603 6.50911 13.4895C7.23828 14.2187 8.1237 14.5833 9.16537 14.5833ZM9.16537 12.9166C8.58203 12.9166 8.08898 12.7152 7.6862 12.3124C7.28342 11.9096 7.08203 11.4166 7.08203 10.8333C7.08203 10.2499 7.28342 9.75686 7.6862 9.35408C8.08898 8.95131 8.58203 8.74992 9.16537 8.74992C9.7487 8.74992 10.2418 8.95131 10.6445 9.35408C11.0473 9.75686 11.2487 10.2499 11.2487 10.8333C11.2487 11.4166 11.0473 11.9096 10.6445 12.3124C10.2418 12.7152 9.7487 12.9166 9.16537 12.9166Z"
                    fill="#527AA1"
                  />
                </svg>
              </div>

              <div
                v-else
                class="selected-photo"
              >
                <img
                  :src="frontPhotoImageInline"
                  alt="selected photo"
                >

                <div class="buttons">
                  <button @click="zoomPhotoHandler('frontPhoto')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.9987 13.3333C11.0404 13.3333 11.9258 12.9687 12.6549 12.2395C13.3841 11.5103 13.7487 10.6249 13.7487 9.58325C13.7487 8.54159 13.3841 7.65617 12.6549 6.927C11.9258 6.19784 11.0404 5.83325 9.9987 5.83325C8.95703 5.83325 8.07161 6.19784 7.34245 6.927C6.61328 7.65617 6.2487 8.54159 6.2487 9.58325C6.2487 10.6249 6.61328 11.5103 7.34245 12.2395C8.07161 12.9687 8.95703 13.3333 9.9987 13.3333ZM9.9987 11.8333C9.3737 11.8333 8.84245 11.6145 8.40495 11.177C7.96745 10.7395 7.7487 10.2083 7.7487 9.58325C7.7487 8.95825 7.96745 8.427 8.40495 7.9895C8.84245 7.552 9.3737 7.33325 9.9987 7.33325C10.6237 7.33325 11.1549 7.552 11.5924 7.9895C12.0299 8.427 12.2487 8.95825 12.2487 9.58325C12.2487 10.2083 12.0299 10.7395 11.5924 11.177C11.1549 11.6145 10.6237 11.8333 9.9987 11.8333ZM9.9987 15.8333C7.97092 15.8333 6.1237 15.2673 4.45703 14.1353C2.79036 13.0034 1.58203 11.486 0.832031 9.58325C1.58203 7.68047 2.79036 6.16311 4.45703 5.03117C6.1237 3.89922 7.97092 3.33325 9.9987 3.33325C12.0265 3.33325 13.8737 3.89922 15.5404 5.03117C17.207 6.16311 18.4154 7.68047 19.1654 9.58325C18.4154 11.486 17.207 13.0034 15.5404 14.1353C13.8737 15.2673 12.0265 15.8333 9.9987 15.8333ZM9.9987 14.1666C11.5681 14.1666 13.0091 13.7534 14.3216 12.927C15.6341 12.1006 16.6376 10.986 17.332 9.58325C16.6376 8.18047 15.6341 7.06589 14.3216 6.2395C13.0091 5.41311 11.5681 4.99992 9.9987 4.99992C8.42925 4.99992 6.98828 5.41311 5.67578 6.2395C4.36328 7.06589 3.35981 8.18047 2.66536 9.58325C3.35981 10.986 4.36328 12.1006 5.67578 12.927C6.98828 13.7534 8.42925 14.1666 9.9987 14.1666Z"
                        fill="white"
                      />
                    </svg>
                  </button>

                  <button @click="deletePhotoHandler('frontPhoto')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5.83203 17.5C5.3737 17.5 4.98134 17.3368 4.65495 17.0104C4.32856 16.684 4.16536 16.2917 4.16536 15.8333V5H3.33203V3.33333H7.4987V2.5H12.4987V3.33333H16.6654V5H15.832V15.8333C15.832 16.2917 15.6688 16.684 15.3424 17.0104C15.0161 17.3368 14.6237 17.5 14.1654 17.5H5.83203ZM14.1654 5H5.83203V15.8333H14.1654V5ZM7.4987 14.1667H9.16536V6.66667H7.4987V14.1667ZM10.832 14.1667H12.4987V6.66667H10.832V14.1667Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="scans-block-item">
              <span class="title">Backend Photo</span>

              <div
                v-if="!backendPhotoImageInline"
                class="image-upload"
                :class="{error: backendPhotoImageError}"
              >
                <input
                  class="file-input"
                  type="file"
                  accept="image/*"
                  @change="event => onPhotoChange(event,'backendPhoto')"
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M2.4987 17.4999C2.04036 17.4999 1.648 17.3367 1.32161 17.0103C0.995226 16.6839 0.832031 16.2916 0.832031 15.8333V5.83325C0.832031 5.37492 0.995226 4.98256 1.32161 4.65617C1.648 4.32978 2.04036 4.16658 2.4987 4.16658H5.1237L6.66536 2.49992H11.6654V4.16658H7.39453L5.8737 5.83325H2.4987V15.8333H15.832V8.33325H17.4987V15.8333C17.4987 16.2916 17.3355 16.6839 17.0091 17.0103C16.6827 17.3367 16.2904 17.4999 15.832 17.4999H2.4987ZM15.832 5.83325V4.16658H14.1654V2.49992H15.832V0.833252H17.4987V2.49992H19.1654V4.16658H17.4987V5.83325H15.832ZM9.16537 14.5833C10.207 14.5833 11.0924 14.2187 11.8216 13.4895C12.5508 12.7603 12.9154 11.8749 12.9154 10.8333C12.9154 9.79158 12.5508 8.90617 11.8216 8.177C11.0924 7.44783 10.207 7.08325 9.16537 7.08325C8.1237 7.08325 7.23828 7.44783 6.50911 8.177C5.77995 8.90617 5.41536 9.79158 5.41536 10.8333C5.41536 11.8749 5.77995 12.7603 6.50911 13.4895C7.23828 14.2187 8.1237 14.5833 9.16537 14.5833ZM9.16537 12.9166C8.58203 12.9166 8.08898 12.7152 7.6862 12.3124C7.28342 11.9096 7.08203 11.4166 7.08203 10.8333C7.08203 10.2499 7.28342 9.75686 7.6862 9.35408C8.08898 8.95131 8.58203 8.74992 9.16537 8.74992C9.7487 8.74992 10.2418 8.95131 10.6445 9.35408C11.0473 9.75686 11.2487 10.2499 11.2487 10.8333C11.2487 11.4166 11.0473 11.9096 10.6445 12.3124C10.2418 12.7152 9.7487 12.9166 9.16537 12.9166Z"
                    fill="#527AA1"
                  />
                </svg>
              </div>

              <div
                v-else
                class="selected-photo"
              >
                <img
                  :src="backendPhotoImageInline"
                  alt="selected photo"
                >

                <div class="buttons">
                  <button @click="zoomPhotoHandler('backendPhoto')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.9987 13.3333C11.0404 13.3333 11.9258 12.9687 12.6549 12.2395C13.3841 11.5103 13.7487 10.6249 13.7487 9.58325C13.7487 8.54159 13.3841 7.65617 12.6549 6.927C11.9258 6.19784 11.0404 5.83325 9.9987 5.83325C8.95703 5.83325 8.07161 6.19784 7.34245 6.927C6.61328 7.65617 6.2487 8.54159 6.2487 9.58325C6.2487 10.6249 6.61328 11.5103 7.34245 12.2395C8.07161 12.9687 8.95703 13.3333 9.9987 13.3333ZM9.9987 11.8333C9.3737 11.8333 8.84245 11.6145 8.40495 11.177C7.96745 10.7395 7.7487 10.2083 7.7487 9.58325C7.7487 8.95825 7.96745 8.427 8.40495 7.9895C8.84245 7.552 9.3737 7.33325 9.9987 7.33325C10.6237 7.33325 11.1549 7.552 11.5924 7.9895C12.0299 8.427 12.2487 8.95825 12.2487 9.58325C12.2487 10.2083 12.0299 10.7395 11.5924 11.177C11.1549 11.6145 10.6237 11.8333 9.9987 11.8333ZM9.9987 15.8333C7.97092 15.8333 6.1237 15.2673 4.45703 14.1353C2.79036 13.0034 1.58203 11.486 0.832031 9.58325C1.58203 7.68047 2.79036 6.16311 4.45703 5.03117C6.1237 3.89922 7.97092 3.33325 9.9987 3.33325C12.0265 3.33325 13.8737 3.89922 15.5404 5.03117C17.207 6.16311 18.4154 7.68047 19.1654 9.58325C18.4154 11.486 17.207 13.0034 15.5404 14.1353C13.8737 15.2673 12.0265 15.8333 9.9987 15.8333ZM9.9987 14.1666C11.5681 14.1666 13.0091 13.7534 14.3216 12.927C15.6341 12.1006 16.6376 10.986 17.332 9.58325C16.6376 8.18047 15.6341 7.06589 14.3216 6.2395C13.0091 5.41311 11.5681 4.99992 9.9987 4.99992C8.42925 4.99992 6.98828 5.41311 5.67578 6.2395C4.36328 7.06589 3.35981 8.18047 2.66536 9.58325C3.35981 10.986 4.36328 12.1006 5.67578 12.927C6.98828 13.7534 8.42925 14.1666 9.9987 14.1666Z"
                        fill="white"
                      />
                    </svg>
                  </button>

                  <button @click="deletePhotoHandler('backendPhoto')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5.83203 17.5C5.3737 17.5 4.98134 17.3368 4.65495 17.0104C4.32856 16.684 4.16536 16.2917 4.16536 15.8333V5H3.33203V3.33333H7.4987V2.5H12.4987V3.33333H16.6654V5H15.832V15.8333C15.832 16.2917 15.6688 16.684 15.3424 17.0104C15.0161 17.3368 14.6237 17.5 14.1654 17.5H5.83203ZM14.1654 5H5.83203V15.8333H14.1654V5ZM7.4987 14.1667H9.16536V6.66667H7.4987V14.1667ZM10.832 14.1667H12.4987V6.66667H10.832V14.1667Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="submit-button"
            @click="submitKycHandler"
          >
            Save
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isZoomedPhoto"
      class="zoom-photo-wrapper"
    >
      <div
        class="close"
        @click="closeZoomedPhoto"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4825_285)">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#527AA1"
            />
          </g>
          <defs>
            <clipPath id="clip0_4825_285">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <img
        :src="zoomedPhoto"
        alt="zoomed photo"
      >
    </div>
  </div>
</template>

<script>
import TradeService from "@/services/trade.service";

export default {
  components: {},
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
  },
emits: ['update', 'closeKyc'],
  data() {
    return {
      /*clientUid: null,*/
      firstName: null,
      lastName: null,
      birthDay: null,
      expireDay: null,
      identityNumber: null,
      /*isIssueCountry: false,
      selectedIssueCountry: 'CN',
      switchersIssueCountry: ['CN', 'US', 'DE', 'JP'],*/
      issueCountry: null,


      isIdentityType: false,
      selectedIdentityType: 'Drivinglicense',
      switchersIdentityType: ['Drivinglicense', 'Idcard', 'Passport'],
      isZoomedPhoto: false,
      zoomedPhoto: null,
      /*face photo*/
      selectedFacePhotoFile: null,
      facePhotoImageInline: null,
      /*front photo*/
      selectedFrontPhotoFile: null,
      frontPhotoImageInline: null,
      /*backend photo*/
      selectedBackendPhotoFile: null,
      backendPhotoImageInline: null,
      /*error*/
      /*clientUidError: false,*/
      firstNameError: false,
      lastNameError: false,
      birthDayError: false,
      expireDayError: false,
      issueCountryError: false,
      identityNumberError: false,
      facePhotoImageError: false,
      frontPhotoImageError: false,
      backendPhotoImageError: false
    }
  },
  mounted() {

  },
  methods: {
    focusOn(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
      }
    },
    onPhotoChange(event, type) {
      const file = event.target.files[0];

      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert('Only JPG or PNG allowed!');
        return false;
      }

      if (file.size > 2 * 1024 * 1024) {
        alert('File too large! (max 2 mb)');
        return false;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64Data = reader.result;

        if (type === 'facePhoto') {
          this.selectedFacePhotoFile = base64Data;
          this.facePhotoImageInline = base64Data;
          this.facePhotoImageError = false
        }
        if (type === 'frontPhoto') {
          this.selectedFrontPhotoFile = base64Data;
          this.frontPhotoImageInline = base64Data;
          this.frontPhotoImageError = false
        }
        if (type === 'backendPhoto') {
          this.selectedBackendPhotoFile = base64Data;
          this.backendPhotoImageInline = base64Data;
          this.backendPhotoImageError = false
        }
      };

      reader.onerror = (error) => {
        console.error('Error reading file: ', error);
      };

      reader.readAsDataURL(file);
    },
    deletePhotoHandler(type) {
      if (type === 'facePhoto') {
        this.selectedFacePhotoFile = null;
        this.facePhotoImageInline = null;
      }

      if (type === 'frontPhoto') {
        this.selectedFrontPhotoFile = null;
        this.frontPhotoImageInline = null;
      }

      if (type === 'backendPhoto') {
        this.selectedBackendPhotoFile = null;
        this.backendPhotoImageInline = null;
      }
    },
    zoomPhotoHandler(type) {
      this.isZoomedPhoto = true

      if (type === 'facePhoto') {
        this.zoomedPhoto = this.facePhotoImageInline
      }

      if (type === 'frontPhoto') {
        this.zoomedPhoto = this.frontPhotoImageInline
      }

      if (type === 'backendPhoto') {
        this.zoomedPhoto = this.backendPhotoImageInline
      }
    },
    closeZoomedPhoto() {
      this.isZoomedPhoto = false;
      this.zoomedPhoto = null;
    },
    submitKycHandler() {
      /*if (!this.clientUid) {
        this.clientUidError = true;
      }*/

      if (!this.issueCountry) {
        this.issueCountryError = true;
      }

      if (!this.firstName) {
        this.firstNameError = true;
      }

      if (!this.lastName) {
        this.lastNameError = true;
      }

      if (!this.birthDay) {
        this.birthDayError = true;
      }

      if (!this.expireDay) {
        this.expireDayError = true;
      }

      if (!this.identityNumber) {
        this.identityNumberError = true;
      }

      if (!this.selectedFacePhotoFile) {
        this.facePhotoImageError = true;
      }

      if (!this.selectedFrontPhotoFile) {
        this.frontPhotoImageError = true;
      }

      if (!this.selectedBackendPhotoFile) {
        this.backendPhotoImageError = true;
      }

      if (this.issueCountryError || this.firstNameError || this.lastNameError || this.birthDayError || this.expireDayError || this.identityNumberError || this.facePhotoImageError || this.frontPhotoImageError || this.backendPhotoImageError) {
        return false
      }

      const query = {
        firstName: this.firstName,
        lastName: this.lastName,
        birthDate: this.birthDay,
        expireDate: this.expireDay,
        identityNumber: this.identityNumber,
        identityType: this.selectedIdentityType,
        issueCountry: this.issueCountry,
        backendPhoto: this.selectedBackendPhotoFile,
        facePhoto: this.selectedFacePhotoFile,
        frontPhoto: this.selectedFrontPhotoFile
      }

      TradeService.submitKyc(this.id,query).then((res) => {
        if (res.status === 200) {
          this.$emit('update')
          this.$emit('closeKyc')
        }
      }).catch((error) => {
        alert(error.response.data.result)
      });
    }
  }
}
</script>

<style lang="scss">
.kyc-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(26, 36, 43, 0.8);
  display: grid;
  place-items: center;
  z-index: 100;

  &_modal {
    display: flex;
    flex-direction: row;
    position: relative;
    max-width: 740px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 48px 0px rgba(6, 59, 122, 0.08), 0px 1px 4px 0px rgba(6, 59, 122, 0.06);

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 6px;
      padding: 6px;
      transition: all .3s ease-in-out;
      cursor: pointer;

      svg {
        path {
          transition: all .3s ease-in-out;
        }
      }

      &:hover {
        background: var(--trade-hover, rgba(0, 122, 255, 0.06));

        svg {
          path {
            fill: rgba(26, 36, 43, 1);
          }
        }
      }
    }

    .left-block,.right-block {
      display: flex;
      flex-direction: column;
      width: 50%;
      max-width: 370px;
      padding: 28px 32px 32px 31px;
      box-sizing: border-box;

      .title {
        margin-bottom: 16px;
        color: #1A242B;
        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.4px;
      }
    }

    .left-block {
      border-right: 1px solid #E0E9F4;
    }

    .right-block {

      .scans-block {
        margin-top: 8px;

        .scans-block-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;

          .scans-block-item {
            display: flex;
            flex-direction: column;
            min-width: 147px;

            .title {
              margin-bottom: 8px;
              color: #527AA1;
              font-family: Rubik, sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }

            .image-upload {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              height: 82px;
              width: 100%;
              border-radius: 6px;
              background: rgba(0, 122, 255, 0.06);
              cursor: pointer;
              transition: all .3s;

              >input {
                position: absolute;
                height: 82px;
                width: 100%;
                cursor: pointer;
                opacity: 0;
              }

              &:hover {
                background: #E0EFFF;

                svg path {
                  transition: all .3s ease-in-out;
                  fill: #1A242B;
                }
              }

              &.error {
                border: 2px solid #FF603D;
              }
            }

            .selected-photo {
              position: relative;
              width: 100%;
              height: 82px;
              cursor: pointer;
              border-radius: 6px;
              overflow: hidden;

              >img {
                width: 100%;
                height: 82px;
                object-fit: cover;
              }

              .buttons {
                display: none;
                gap: 2px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                >button {
                  display: inline-flex;
                  align-items: center;
                  width: 32px;
                  height: 32px;
                  flex-shrink: 0;
                  border-radius: 6px;
                  background: rgba(26, 36, 43, 0.80);
                  border: none;
                  cursor: pointer;
                  transition: all .3s ease-in-out;

                  &:hover {
                    background: #1A242B;
                  }
                }
              }

              &:hover .buttons {
                display: inline-flex;
              }
            }
          }
        }

        .submit-button {
          text-align: center;
          line-height: 48px;
          margin-top: 74px;
          width: 100%;
          height: 48px;
          color: #FFF;
          font-family: Rubik, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.2px;
          flex-shrink: 0;
          border-radius: 6px;
          border: 1px solid rgba(130, 170, 200, 0.24);
          background: var(--trade-trade-blue, #007AFF);
          cursor: pointer;
        }
      }
    }

    .inputs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .horizontal-wrapper {
        display: flex;
        gap: 12px;

        >.inputs--item {
          width: calc(50% - 6px) !important;
        }
      }
    }


    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px 0;

      &--item {
        width: 100%;
        border-radius: 6px;
        outline: 1px solid rgba(124, 163, 210, 0.24);
        padding: 6px 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        cursor: pointer;
        transition: all .2s ease-in-out;
        border: none;
        height: 48px;

        &:hover {
          background: var(--trade-hover, rgba(0, 122, 255, 0.06));

          .desc {
            color: #1A242B;
          }
        }

        .input {
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
        }


        .desc {
          color: var(--trade-trade-gray, #527AA1);
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          transform: translateY(8px);
          transition: all .2s ease-in-out;
        }

        input {
          background: none;
          border: none;
          outline: none;
          color: var(--trade-trade-black, #1A242B);
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          max-width: 188px;
          cursor: pointer;
        }

        .coin {
          color: var(--trade-trade-gray, #527AA1);
          text-align: right;
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 183.333% */
        }

        &_switchers {
          display: flex;
          flex-direction: column;
          gap: 6px;
          margin-top: 8px;

          .switchers {
            &--item {
              width: 100%;
              padding: 0;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
              letter-spacing: -0.2px;
              transition: all .3s ease-in-out;

              &_active {
                font-weight: 500;
              }
            }
          }
        }

        &:focus-within {
          border: none;
          outline: 2px solid var(--trade-trade-black, #1A242B);

          .desc {
            font-size: 12px;
            line-height: 14px;
            color: #1A242B;
            transform: translateY(0);
          }

          &:hover {
            background: none;
          }
        }
      }

      &--item.focus {
        .desc {
          transform: translateY(0);
          font-size: 12px;
          line-height: 14px;
          color: #527AA1;
        }
      }

      &--item.error {
        outline: 2px solid var(--trade-trade-red, #FF603D);

        .desc {
          color: #FF603D;
        }
      }

      .type {
        width: 100%;
        padding: 6px 8px 6px 12px;
        border-radius: 6px;
        border: 1px solid rgba(124, 163, 210, 0.24);
        position: relative;
        cursor: pointer;
        box-sizing: border-box;

        .select-input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &_selected {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }

          .selected {
            display: flex;
            flex-direction: column;

            .desc {
              color: var(--trade-trade-gray, #527AA1);
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }

            .value {
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */
            }
          }

          &_dropmenu {
            z-index: 9;
            position: absolute;
            padding: 6px 0;
            top: calc(100% + 4px);
            left: 0;
            width: 100%;
            border-radius: 6px;
            background: #FFF;
            box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
            max-height: 300px;
            overflow: auto;

            .item {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 8px 20px 8px 12px;
              cursor: pointer;
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 114.286% */

              &:hover {
                background: var(--trade-hover, rgba(0, 122, 255, 0.06));
              }

              &--market {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                font-weight: 500;
              }
            }
          }
        }

        &:hover {
          background: var(--trade-hover, rgba(0, 122, 255, 0.06));
        }
      }
    }
  }
}

.zoom-photo-wrapper {
  position: absolute;
  height: 70%;
  overflow: hidden;
  border-radius: 20px;

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    height: 32px;
    width: 32px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  >img {
    height: 100%;
    width: 100%;
  }
}
</style>

import axios from 'axios';

const StatusCode = {
    Unauthorized: 401,
    Forbidden: 403,
    TooManyRequests: 429,
    InternalServerError: 500,
}

export function handleResponseError(error) {
    if (error.code === 'ECONNABORTED') {
        console.warn('Timeout error', error);
        return Promise.reject(error);
    }
    if (error.code === 'ERR_CANCELED' || axios.isCancel?.(error)) {
        console.warn('Запрос был отменён', error);
        return Promise.reject(error);
    }

    if (!error.response) {
        console.warn('Server error', error);
        return Promise.reject(error);
    }

    const status = error.response.status;
    switch (status) {
        case StatusCode.InternalServerError:
            console.error('Internal Server Error (500)');
            break;
        case StatusCode.Forbidden:
            console.error('Forbidden (403)');
            break;
        case StatusCode.Unauthorized:
            localStorage.token = '';
            location.href = '/login';
            break;
        case StatusCode.TooManyRequests:
            console.warn('Too many requests (429)');
            break;
        default:
            console.error('Unknown status', status);
    }

    return Promise.reject(error);
}

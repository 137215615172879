<template>
  <!--  <AccountLayout>
    <template #title>
      <h2>List of client users</h2>
    </template>
    <template #content>
      <div
        class="clientUsersList main-block"
        :class="{'popup-active': Profile || UserItem || IpList}"
      >
        <loading
          v-if="!usersData && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div
          class="table-block"
        >
          <div class="table-block-header">
            <div class="table-block-header-title">
              All client users
            </div>
            <div class="table-block-header-filters">
              <div class="table-block-header-filters-search">
                <input
                  v-model="searchInput"
                  type="text"
                  placeholder="Search"
                  @keyup="searchLogin"
                >
                <img
                  @click="clearSearch"
                  v-if="isSearch === true"
                  class="close-search"
                  src="../../assets/img/icons/false.svg"
                  alt="close"
                >
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{
                      'table-id': item.key === 'id',
                      'table-name': item.key === 'name',
                      'table-email': item.key === 'email',
                      'table-email-verified': item.key === 'isEmailVerified',
                      'table-createDate': item.key === 'createDate',
                      'table-status': item.key === 'status',
                    }"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort && !item.noSort" />
                      <sort-a-z v-if="!item.noSort && item.sort === 'asc'" />
                      <sort-z-a v-if="!item.noSort && item.sort === 'desc'" />
                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ClientUserListItem
                  v-for="(clientUser) in usersData"
                  :key="clientUser.id"
                  :client-user="clientUser"
                  @toggle-dropdown="handleToggleDropdown"
                  :is-open="openDropdownId === clientUser.id"
                  @close-dropdown="handleCloseDropdown"
                />
              </tbody>
            </table>
            <no-data-block v-if="!usersData || !usersData.length && !isLoading" />
          </div>
        </div>
        <paged-pagination
          v-if="usersData && usersData.length && !isSearch"
          :total="batch.length"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
        <user-ip-list v-if="IpList" />
      </div>
    </template>
  </AccountLayout>-->
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_5747_3538)">
              <path
                d="M12.0004 2.40002C6.69639 2.40002 2.40039 6.69602 2.40039 12C2.40039 17.304 6.69639 21.6 12.0004 21.6C17.3044 21.6 21.6004 17.304 21.6004 12C21.6004 6.69602 17.3044 2.40002 12.0004 2.40002ZM12.0004 6.60002C13.9924 6.60002 15.6004 8.20802 15.6004 10.2C15.6004 12.192 13.9924 13.8 12.0004 13.8C10.0084 13.8 8.40039 12.192 8.40039 10.2C8.40039 8.20802 10.0084 6.60002 12.0004 6.60002ZM12.0004 19.8C9.54039 19.8 7.35639 18.66 5.91639 16.872C7.65639 15.696 9.74439 15 12.0004 15C14.2564 15 16.3444 15.696 18.0844 16.872C16.6444 18.66 14.4604 19.8 12.0004 19.8Z"
                fill="#0065FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_5747_3538">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          Users
        </h2>
      </div>
    </template>
    <template #content>
      <div
        class="main-block clientUsersList"
      >
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="table-block">
          <div
            ref="stickyBlock"
            class="table-block-header"
            :class="{ sticky: false }"
          >
            <div class="table-block-header-title">
              All client users
            </div>

            <div class="filters-component-container">
              <div class="filters-component-left-side">
                <Search
                  @searchHandler="searchLogin"
                />
                <div
                  v-if="selectedSearchInput"
                  class="selected-filter-container"
                >
                  <div
                    v-if="selectedSearchInput"
                    class="selected-filter-item"
                  >
                    <span>Search:</span>
                    <span>{{ selectedSearchInput }}</span>
                    <FilterClose @click="clearSearch" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <Transition name="committee">
              <table class="table">
                <thead>
                  <tr class="table-headers">
                    <th
                      v-for="(item, index) in tableTh"
                      :id="item.id"
                      :key="index"
                      scope="col"
                      :class="'table-' + item.key"
                    >
                      <div
                        class="text"
                        @click="sortData(item)"
                        @mouseover="currentIndex = index"
                        @mouseleave="currentIndex = null"
                      >
                        {{ item.name }}
                        <sort-passive v-if="!item.sort && !item.noSort" />
                        <sort-a-z v-if="!item.noSort && item.sort === 'asc'" />
                        <sort-z-a v-if="!item.noSort && item.sort === 'desc'" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="Boolean(usersData)">
                  <ClientUserListItem
                    v-for="(clientUser) in usersData"
                    :key="clientUser.id"
                    :client-user="clientUser"
                    :is-open="openDropdownId === clientUser.id"
                    :is-nft-open="openNftDropdownId === clientUser.id"
                    @toggle-dropdown="handleToggleDropdown"
                    @toggle-nft-dropdown=handleNftToggleDropdown
                    @close-dropdown="handleCloseDropdown"
                    @close-nft-dropdown="handleNftCloseDropdown"
                  />
                </tbody>
              </table>
            </Transition>
            <Transition name="committee">
              <no-data-block v-if="!Boolean(usersData) && !isLoading" />
            </Transition>
          </div>
          <paged-pagination
            v-if="usersData && usersData.length && !isSearch"
            :total="batch.length"
            :current="page"
            @change-page="pageHandler"
            @prev-page="pageHandler"
            @next-page="pageHandler"
          />
        </div>
      </div>
    </template>
  </AccountLayoutNew>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import {mapGetters} from 'vuex';
import HoverText from "../coins/partials/hoverText";
import filterMixin from "../../mixins/filter.mixin";
import helperMixin from "../../mixins/helper.mixin";
import SortZA from "../../icons/sorting/sort-z-a";
import SortAZ from "../../icons/sorting/sort-a-z";
import SortPassive from "../../icons/sorting/sort-passive";
import {TABLE_TH} from "@/pages/clientUsers/clientUserData";
import PagedPagination from "@/components/admin/PagedPagiantion.vue";
import UserIpList from "@/pages/users/partials/UserIpList";
import Loading from 'vue-loading-overlay';
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import AdminService from '@/services/admin.service';
import ClientUserListItem from '@/pages/clientUsers/partials/ClientUserListItem.vue';
import EmailLetterItem from '@/pages/email-letters/partials/EmailLetterItem.vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Search from '@/components/admin/Search.vue';
import FilterClose from '@/icons/filterClose.vue';

export default {
  name: "ClientUsersList",
  components: {
    FilterClose,
    Search,
    AccountLayoutNew,
    ClientUserListItem,
    NoDataBlock,
    PagedPagination,
    SortPassive,
    SortAZ,
    SortZA,
    Loading
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      search: null,
      // search input
      selectedSearchInput: '',
      searchInput: '',
      originUsers: null,
      currentSorting: 'id',
      isAdd: false,
      batch: [],
      page: 1,
      usersData: [],
      options: {
        chunk: 4
      },
      currentPerPage: 20,
      currentIndex: null,
      tableTh: TABLE_TH,
      isLoading: false,
      fullPage: true,
      userData: null,
      isSearch: false,
      openDropdownId: null,
      openNftDropdownId: null
    }
  },
  computed: {
    ...mapGetters({
      UserItem: 'getUserItem',
      Profile: 'getProfile',
      IpList: 'getIpList',
    }),
  },
  mounted() {
    this.getClientUsersDataList();
    this.emitter.on('edit-user-popup', (data) => {
      this.userData = data
    })
  },
  methods: {
   /* handleChangeFav() {
      this.getUsersDataList();
    },*/
    getClientUsersDataList() {
      this.isLoading = true;

      return AdminService.getClientUsersList().then((result) => {
        if (result.status === 200) {
          this.usersData = result.data.result.sort((a, b) => b.id - a.id);

          this.originUsers = this.usersData;
          this.batch = this.batchDataNew(this.usersData, this.currentPerPage);

          this.page = 1;
          this.usersData = this.batch[0];
          this.isLoading = false;
        }
      }).catch((error) => {
        this.isLoading = false;
        /*throw new Error('Get users list failed ' + error)*/
      })
    },
    batchDataNew(data, perPage) {
      return data.map((e, i) => {
        return i % perPage === 0 ? data.slice(i, i + perPage) : null;
      }).filter(e => e);
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      if (this.isSearch === false) {
        this.usersData = this.originUsers
      }
      switch (type.key) {
        case 'id':
          this.usersData = this.usersData.sort((a, b) => {
            return type.sort === 'asc' ? a.id - b.id : b.id - a.id;
          });
          break;
        case 'name':
          this.usersData = this.usersData.sort((a, b) => {
            const sign = type.sort === 'asc' ? 1 : -1;
            const aName = (a.name || '').toLowerCase();
            const bName = (b.name || '').toLowerCase();

            if (!aName && bName) return 1 * sign;
            if (aName && !bName) return -1 * sign;

            return sign * aName.localeCompare(bName);
          });
          break;
        case 'email':
          this.usersData = this.usersData.sort((a, b) => {
            const sign = type.sort === 'asc' ? 1 : -1;
            const aName = (a.email || '').toLowerCase();
            const bName = (b.email || '').toLowerCase();

            if (!aName && bName) return 1 * sign;
            if (aName && !bName) return -1 * sign;

            return sign * aName.localeCompare(bName);
          });
          break;
        case 'address':
          this.usersData = this.usersData.sort((a, b) => {
            const sign = type.sort === 'asc' ? 1 : -1;
            const aName = (a.walletAddress || '').toLowerCase();
            const bName = (b.walletAddress || '').toLowerCase();

            if (!aName && bName) return 1 * sign;
            if (aName && !bName) return -1 * sign;

            return sign * aName.localeCompare(bName);
          });
          break;
        case 'createDate':
          this.usersData = this.usersData.sort((a, b) => {
            const aDate = Date.parse(a.createDate);
            const bDate = Date.parse(b.createDate);

            return type.sort === 'asc' ? aDate - bDate : bDate - aDate;
          });
          break;
        case 'discount':
          this.usersData = this.usersData.sort((a, b) => {
            return type.sort === 'asc' ? a.discount - b.discount : b.discount - a.discount;
          });
          break;
        case 'order':
          this.usersData = this.usersData.sort((a, b) => {
            const aOrders = a.statsRecord?.ordersCount ?? 0; // Если statsRecord или ordersCount равны null, используем 0
            const bOrders = b.statsRecord?.ordersCount ?? 0;

            return type.sort === 'asc'
              ? aOrders - bOrders
              : bOrders - aOrders;
          });
          break;
        case 'status':
          this.usersData = this.usersData.sort((a, b) => {
            return type.sort === 'asc' ? a.status - b.status : b.status - a.status;
          });
          break;
      }
      this.batch = this.batchDataNew(this.usersData, this.currentPerPage);
      this.usersData = this.batch[this.page-1];
    },
    searchLogin(event) {
      this.selectedSearchInput = event
      this.usersData = this.originUsers;

      if (this.selectedSearchInput.length) {
        this.usersData = this.usersData.filter((item) => {
          const email = (item.email || '').toLowerCase();
          const name = (item.name || '').toLowerCase();
          const walletAddress = (item.walletAddress || '').toLowerCase();

          return email.includes(this.selectedSearchInput) || name.includes(this.selectedSearchInput) || walletAddress.includes(this.selectedSearchInput);
        });
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }

      this.updatePages();
    },
    clearSearch() {
      this.selectedSearchInput = null
      this.isSearch = false;
      this.usersData = this.originUsers;
      this.updatePages();
    },
    updatePages() {
      const batch = this.batchData(this.usersData, this.currentPerPage);
      this.usersData = batch.current;
      this.batch = batch.total;
      this.page = 1;
    },
    pageHandler(page) {
      this.page = page;
      this.usersData = this.batch[page - 1];
    },
    handleToggleDropdown(itemId) {
      if (this.openDropdownId === itemId) {
        this.openDropdownId = null;
      } else {
        this.openDropdownId = itemId;
      }
    },
    handleNftToggleDropdown(itemId) {
      if (this.openNftDropdownId === itemId) {
        this.openNftDropdownId = null;
      } else {
        this.openNftDropdownId = itemId;
      }
    },
    handleCloseDropdown() {
      this.openDropdownId = null;
    },
    handleNftCloseDropdown() {
      this.openNftDropdownId = null;
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/pages/clientUsers/clientUsers";
</style>

<template>
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <path
              d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H2ZM2 16H18V2H2V16ZM4 14H16V12H4V14ZM4 10H8V4H4V10ZM10 10H16V8H10V10ZM10 6H16V4H10V6Z"
              fill="#0065FF"
            />
          </svg>
          Black List
        </h2>
      </div>
    </template>
    <template #content>
      <div
        v-if="depositsData && withdrawalData"
        class="main-block black-list"
      >
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="table-block">
          <div
            ref="stickyBlock"
            class="table-block-header"
            :class="{ sticky: false }"
          >
            <!--<div class="table-block-header-title">
              List of black list
            </div>-->

            <div class="filters-component-container">
              <div class="filters-component-left-side">
<!--                <h2>List of black list</h2>-->
                <search
                  placeholder="Address"
                  @searchHandler="searchHandler"
                />
                <span
                  class="tab-button"
                  :class="{'active': activeTab === 'deposits'}"
                  @click="activeTabHandler('deposits')"
                >
                  Deposits
                </span>
                <span
                  class="tab-button"
                  :class="{'active': activeTab === 'withdrawal'}"
                  @click="activeTabHandler('withdrawal')"
                >
                  Withdrawal
                </span>

                <div
                  v-if="selectedSearchInput"
                  class="selected-filter-container"
                >
                  <div
                    v-if="selectedSearchInput"
                    class="selected-filter-item"
                  >
                    <span>Search:</span>
                    <span>{{ selectedSearchInput }}</span>
                    <FilterClose @click="searchHandler(null)" />
                  </div>
                </div>
              </div>
              <button
                class="add-new-button"
                @click="$router.push({ name: 'Black list new record', params: {activeTab: activeTab} })"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H11.6042L9.9375 4.16667H4.16667V15.8333H15.8333V10.0417L17.5 8.375V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM7.5 12.5V8.95833L15.1458 1.3125C15.3125 1.14583 15.5 1.02083 15.7083 0.9375C15.9167 0.854167 16.125 0.8125 16.3333 0.8125C16.5556 0.8125 16.7674 0.854167 16.9687 0.9375C17.1701 1.02083 17.3542 1.14583 17.5208 1.3125L18.6875 2.5C18.8403 2.66667 18.9583 2.85069 19.0417 3.05208C19.125 3.25347 19.1667 3.45833 19.1667 3.66667C19.1667 3.875 19.1285 4.07986 19.0521 4.28125C18.9757 4.48264 18.8542 4.66667 18.6875 4.83333L11.0417 12.5H7.5ZM9.16667 10.8333H10.3333L15.1667 6L14.5833 5.41667L13.9792 4.83333L9.16667 9.64583V10.8333Z"
                    fill="white"
                  />
                </svg>
                Add new record
              </button>
            </div>
          </div>
          <div class="table-block-table">
            <Transition name="committee">
              <table class="table">
                <thead>
                  <tr class="table-headers">
                    <th
                      v-for="(item, index) in tableTh"
                      :id="item.id"
                      :key="index"
                      scope="col"
                      :class="'table-' + item.key"
                    >
                      <div
                        class="text"
                        @click="sortData(item)"
                        @mouseover="tokenTo = index"
                        @mouseleave="tokenTo = null"
                      >
                        {{ item.name }}
                        <span v-if="item.isSortable">
                          <sort-passive v-if="!item.sort && !item.noSort" />
                          <sort-a-z v-if="!item.noSort && item.sort === 'asc'" />
                          <sort-z-a v-if="!item.noSort && item.sort === 'desc'" />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="depositsData.length && activeTab === 'deposits'">
                  <BlackListItem
                    v-for="listItem in depositsData"
                    :key="listItem.id"
                    :list-item="listItem"
                    :active-tab="activeTab"
                  />
                </tbody>
                <tbody v-if="withdrawalData.length && activeTab === 'withdrawal'">
                  <BlackListItem
                    v-for="listItem in withdrawalData"
                    :key="listItem.id"
                    :list-item="listItem"
                    :active-tab="activeTab"
                  />
                </tbody>
              </table>
            </Transition>
            <no-data-block v-if="!withdrawalData.length && !depositsData.length && !isLoading" />
          </div>
          <!--          <paged-pagination
            v-if="articlesData.length && !isLoading && maxPage"
            :total="maxPage"
            :current="page"
            @change-page="pageHandler"
            @prev-page="pageHandler"
            @next-page="pageHandler"
          />-->
        </div>
      </div>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Loading from 'vue-loading-overlay';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import SortZA from '@/icons/sorting/sort-z-a.vue';
import { TABLE_TH} from '@/pages/black-list/blackListData';
import filterMixin from '@/mixins/filter.mixin';
import helperMixin from '@/mixins/helper.mixin';
import NoDataBlock from '@/components/admin/NoDataBlock.vue';
import BlackListService from '@/services/black-list.service';
import BlackListItem from '@/pages/black-list/BlackListItem.vue';
import Search from '@/components/admin/Search.vue';
import FilterClose from '@/icons/filterClose.vue';

export default defineComponent({
  components: {
    FilterClose,
    Search,
    BlackListItem,
    NoDataBlock,
    SortZA, SortPassive, SortAZ, Loading, AccountLayoutNew},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      depositsData: [],
      withdrawalData: [],
      originDepositData:[],
      originWithdrawalData:[],
      tableTh: TABLE_TH,
      isLoading: true,
      maxPage: 0,
      page: 1,
      tokenTo: false,
      isUpdate: false,
      updateText: '',
      activeTab: 'deposits',
      selectedSearchInput: ''
    }
  },
  mounted() {
    this.mountList()
  },
  beforeUnmount() {
  },
  methods: {
    mountList() {
      BlackListService.getWithdrawAll().then((result) => {
        if (result.status === 200) {
          this.withdrawalData = result.data.result.sort((a, b) => b.id - a.id);
          this.originWithdrawalData = this.withdrawalData
        }
      }).catch((error) => {
        console.log(error.response.data.result, 'get withdrawalData error')
      })


      BlackListService.getDepositAll().then((result) => {
        if (result.status === 200) {
          this.depositsData = result.data.result.sort((a, b) => b.id - a.id);
          this.originDepositData = this.depositsData
          this.isLoading = false;
        }
      }).catch((error) => {
        console.log(error.response.data.result, 'get depositsData error')
      })
    },
    activeTabHandler(tab) {
      this.activeTab = tab;
    },
    // sort
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });

      switch (type.key) {
        case 'id':
          this.depositsData = this.depositsData.sort((a, b) => {
            const valA = a.id || 0
            const valB = b.id || 0
            return type.sort === 'asc' ? valA - valB : valB - valA
          })
          this.withdrawalData = this.withdrawalData.sort((a, b) => {
            const valA = a.id || 0
            const valB = b.id || 0
            return type.sort === 'asc' ? valA - valB : valB - valA
          })
          break
        case 'networkId':
          this.depositsData = this.depositsData.sort((a, b) => {
            const valA = a.network || ''
            const valB = b.network || ''
            if (type.sort === 'asc') return valA.localeCompare(valB)
            return valB.localeCompare(valA)
          })
          this.withdrawalData = this.withdrawalData.sort((a, b) => {
            const valA = a.network || ''
            const valB = b.network || ''
            if (type.sort === 'asc') return valA.localeCompare(valB)
            return valB.localeCompare(valA)
          })
          break
        case 'addressWeb3':
          this.depositsData = this.depositsData.sort((a, b) => {
            const valA = a.address || ''
            const valB = b.address || ''
            if (type.sort === 'asc') return valA.localeCompare(valB)
            return valB.localeCompare(valA)
          })
          this.withdrawalData = this.withdrawalData.sort((a, b) => {
            const valA = a.address || ''
            const valB = b.address || ''
            if (type.sort === 'asc') return valA.localeCompare(valB)
            return valB.localeCompare(valA)
          })
          break
        case 'created':
          this.depositsData = this.depositsData.sort((a, b) => {
            const aDate = a.created ? Date.parse(a.created) : 0
            const bDate = b.created ? Date.parse(b.created) : 0
            return type.sort === 'asc' ? aDate - bDate : bDate - aDate
          })
          this.withdrawalData = this.withdrawalData.sort((a, b) => {
            const aDate = a.created ? Date.parse(a.created) : 0
            const bDate = b.created ? Date.parse(b.created) : 0
            return type.sort === 'asc' ? aDate - bDate : bDate - aDate
          })
          break
        case 'action':
          this.depositsData = this.depositsData.sort((a, b) => {
            const valA = a.isActive ?? 0
            const valB = b.isActive ?? 0
            return type.sort === 'asc' ? valA - valB : valB - valA
          })
          this.withdrawalData = this.withdrawalData.sort((a, b) => {
            const valA = a.isActive ?? 0
            const valB = b.isActive ?? 0
            return type.sort === 'asc' ? valA - valB : valB - valA
          })
          break
      }
    },
    searchHandler(inputText) {
      if (inputText === null) {
        this.depositsData = this.originDepositData;
        this.withdrawalData = this.originWithdrawalData;
        this.selectedSearchInput = ''
      } else {
        this.depositsData = this.originDepositData.filter(item =>
          item.address && item.address.toLowerCase().includes(inputText.toLowerCase())
        );
        this.withdrawalData = this.originWithdrawalData.filter(item =>
          item.address && item.address.toLowerCase().includes(inputText.toLowerCase())
        );
        this.selectedSearchInput = inputText;
      }
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.black-list {
  @media (max-width: 1078px) {
    margin-top: 16px;
  }
  .table-block-header {
    flex-direction: row;
    @media (max-width: 1140px) {
      flex-direction: column !important;
    }
  }

  .table-block-table table {
    @media (max-width: 1079px) {
      width: 100%;
    }
  }

  tr.table-headers th.table-id {
    padding-left: 32px;
    min-width: 42px;
    max-width: 42px;
  }

  tr.table-headers th.table-addressWeb3 {
    padding-right: 32px;
    max-width: 150px;
    min-width: 80px;
  }

  tr.table-headers th.table-networkId {
    min-width: 80px;
  }

  tr.table-headers th.table-comment {
    width: 35%;
    min-width: 120px;
  }

  tr.table-headers th.table-action {
    padding-right: 32px;
    width: 45px;
  }


  .filters-component-container {
    width: 100%;

    .filters-component-left-side {
      display: flex;
      gap: 16px 4px;

      span.tab-button {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 6px 12px 6px;
        height: 32px;
        min-width: fit-content;
        font-size: 12px;
        color: #0065FF;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        background: #EBF3FF;
        border-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          background: #E0EDFF;
        }
      }

      span.active {
        background: #0065FF;
        color: white;
      }
    }

    .add-new-button {
      display: inline-flex;
      padding: 6px 12px 6px 6px;
      align-items: flex-start;
      gap: 4px;
      color: white;
      font-family:  'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      height: 32px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 8px;
      background: $blue-color;
      outline: none;
      border: none;
      cursor: pointer;
      box-sizing: border-box;
      transition: .3s;

      &:hover {
        background: $blue-color-hover;
      }
    }
  }

  .table-block-header.sticky {
    position: fixed;
    top: 0;
    background: #fff;
    width: calc(100% - 385px);
    z-index: 10;

    @media (max-width: 1079px) {
      left: 0;
      width: calc(100% - 32px);
    }
  }

  .table-block-header-title {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 32px;
    min-width: fit-content !important;
  }
}
</style>

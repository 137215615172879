export const binance = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="6" fill="#F0B90B"/>
    <path d="M24.0983 13.5643L21.6641 15.9985L24.0983 18.4328L26.5325 15.9985L24.0983 13.5643Z" fill="white"/>
    <path d="M7.90297 13.5658L5.46875 16L7.90297 18.4342L10.3372 16L7.90297 13.5658Z" fill="white"/>
    <path d="M16 21.6663L11.965 17.6313L9.53125 20.0651L16 26.5338L22.4688 20.0651L20.035 17.6313L16 21.6663Z" fill="white"/>
    <path d="M16 10.3338L20.035 14.3688L22.4688 11.9351L16 5.46631L9.53125 11.9351L11.965 14.3688L16 10.3338Z" fill="white"/>
    <path d="M15.9975 13.5963L13.5938 16L15.9975 18.4037L18.4012 16L15.9975 13.5963Z" fill="white"/>
</svg>`;


export const kraken = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="6" fill="#007AFF"/>
    <path d="M15.9752 6.6665C10.3537 6.6665 5.79688 11.2257 5.79688 16.8496V21.2135C5.79688 22.0162 6.44717 22.6665 7.2499 22.6665C8.05264 22.6665 8.70769 22.0162 8.70769 21.2135V16.8496C8.70769 16.0445 9.3556 15.3942 10.1607 15.3942C10.9635 15.3942 11.6137 16.0445 11.6137 16.8496V21.2135C11.6137 22.0162 12.2641 22.6665 13.0668 22.6665C13.8719 22.6665 14.5222 22.0162 14.5222 21.2135V16.8496C14.5222 16.0445 15.1725 15.3942 15.9752 15.3942C16.7803 15.3942 17.433 16.0445 17.433 16.8496V21.2135C17.433 22.0162 18.0833 22.6665 18.886 22.6665C19.6888 22.6665 20.3391 22.0162 20.3391 21.2135V16.8496C20.3391 16.0445 20.9894 15.3942 21.7969 15.3942C22.5996 15.3942 23.2499 16.0445 23.2499 16.8496V21.2135C23.2499 22.0162 23.9002 22.6665 24.7053 22.6665C25.508 22.6665 26.1583 22.0162 26.1583 21.2135V16.8496C26.1583 11.2257 21.5992 6.6665 15.9752 6.6665Z" fill="white"/>
</svg>`;

export const kucoin = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="6" fill="#23AF91"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.388 16.0017L18.9167 21.5312L22.4064 18.0415C23.0304 17.4797 23.9851 17.5047 24.5788 18.0984C25.1726 18.6922 25.1976 19.6468 24.6357 20.2708L20.0305 24.8769C19.4119 25.4853 18.4198 25.4853 17.8012 24.8769L11.157 18.231V22.1812C11.157 23.053 10.4503 23.7597 9.57851 23.7597C8.70673 23.7597 8 23.053 8 22.1812V9.81714C8 8.94535 8.70673 8.23862 9.57851 8.23862C10.4503 8.23862 11.157 8.94535 11.157 9.81714V13.7673L17.8003 7.12312C18.4187 6.5143 19.4112 6.5143 20.0296 7.12312L24.6383 11.7283C25.2001 12.3523 25.1752 13.307 24.5814 13.9008C23.9877 14.4945 23.033 14.5195 22.409 13.9576L18.9193 10.4679L13.388 16.0017ZM18.9193 14.4215C18.2801 14.4211 17.7036 14.8059 17.4587 15.3964C17.2139 15.9869 17.3488 16.6667 17.8007 17.1188C18.2526 17.5709 18.9324 17.7063 19.5229 17.4617C20.1135 17.2172 20.4986 16.6409 20.4986 16.0017C20.4989 15.5826 20.3326 15.1806 20.0364 14.8841C19.7402 14.5876 19.3384 14.4209 18.9193 14.4206V14.4215Z" fill="white"/>
</svg>`;

export const okxSub = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3935_59658)">
<path d="M32 0H0V32H32V0Z" fill="white"/>
<path d="M11.4269 12.6816H5.08497C5.04586 12.6816 5.00834 12.6972 4.9807 12.7248C4.95303 12.7525 4.9375 12.79 4.9375 12.8291V19.171C4.9375 19.2101 4.95303 19.2477 4.9807 19.2753C5.00834 19.303 5.04586 19.3185 5.08497 19.3185H11.4269C11.466 19.3185 11.5035 19.303 11.5312 19.2753C11.5588 19.2477 11.5744 19.2101 11.5744 19.171V12.8291C11.5744 12.79 11.5588 12.7525 11.5312 12.7248C11.5035 12.6972 11.466 12.6816 11.4269 12.6816ZM9.36206 16.9587C9.36206 16.9978 9.34654 17.0354 9.31886 17.063C9.29122 17.0907 9.25371 17.1062 9.2146 17.1062H7.29726C7.25815 17.1062 7.22064 17.0907 7.19297 17.063C7.16532 17.0354 7.1498 16.9978 7.1498 16.9587V15.0414C7.1498 15.0023 7.16532 14.9648 7.19297 14.9371C7.22064 14.9095 7.25815 14.8939 7.29726 14.8939H9.2146C9.25371 14.8939 9.29122 14.9095 9.31886 14.9371C9.34654 14.9648 9.36206 15.0023 9.36206 15.0414V16.9587Z" fill="black"/>
<path d="M24.7054 14.8945H22.7881C22.7067 14.8945 22.6406 14.9605 22.6406 15.042V16.9593C22.6406 17.0408 22.7067 17.1068 22.7881 17.1068H24.7054C24.7869 17.1068 24.8529 17.0408 24.8529 16.9593V15.042C24.8529 14.9605 24.7869 14.8945 24.7054 14.8945Z" fill="black"/>
<path d="M22.4906 12.6816H20.5733C20.4918 12.6816 20.4258 12.7477 20.4258 12.8291V14.7464C20.4258 14.8279 20.4918 14.8939 20.5733 14.8939H22.4906C22.5721 14.8939 22.6381 14.8279 22.6381 14.7464V12.8291C22.6381 12.7477 22.5721 12.6816 22.4906 12.6816Z" fill="black"/>
<path d="M26.9164 12.6816H24.9991C24.9176 12.6816 24.8516 12.7477 24.8516 12.8291V14.7464C24.8516 14.8279 24.9176 14.8939 24.9991 14.8939H26.9164C26.9978 14.8939 27.0639 14.8279 27.0639 14.7464V12.8291C27.0639 12.7477 26.9978 12.6816 26.9164 12.6816Z" fill="black"/>
<path d="M22.4906 17.1055H20.5733C20.4918 17.1055 20.4258 17.1715 20.4258 17.2529V19.1703C20.4258 19.2517 20.4918 19.3178 20.5733 19.3178H22.4906C22.5721 19.3178 22.6381 19.2517 22.6381 19.1703V17.2529C22.6381 17.1715 22.5721 17.1055 22.4906 17.1055Z" fill="black"/>
<path d="M26.9164 17.1055H24.9991C24.9176 17.1055 24.8516 17.1715 24.8516 17.2529V19.1703C24.8516 19.2517 24.9176 19.3178 24.9991 19.3178H26.9164C26.9978 19.3178 27.0639 19.2517 27.0639 19.1703V17.2529C27.0639 17.1715 26.9978 17.1055 26.9164 17.1055Z" fill="black"/>
<path d="M19.1703 12.6816H17.253C17.1715 12.6816 17.1055 12.7477 17.1055 12.8291V14.7464C17.1055 14.8279 17.1715 14.8939 17.253 14.8939H19.1703C19.2517 14.8939 19.3178 14.8279 19.3178 14.7464V12.8291C19.3178 12.7477 19.2517 12.6816 19.1703 12.6816Z" fill="black"/>
<path d="M19.1703 17.1055H17.253C17.1715 17.1055 17.1055 17.1715 17.1055 17.2529V19.1703C17.1055 19.2517 17.1715 19.3178 17.253 19.3178H19.1703C19.2517 19.3178 19.3178 19.2517 19.3178 19.1703V17.2529C19.3178 17.1715 19.2517 17.1055 19.1703 17.1055Z" fill="black"/>
<path d="M17.1043 15.0397C17.1043 15.0006 17.0887 14.9631 17.0611 14.9355C17.0334 14.9078 16.9959 14.8923 16.9568 14.8923H14.892V12.8291C14.892 12.79 14.8764 12.7525 14.8488 12.7248C14.8211 12.6972 14.7836 12.6816 14.7445 12.6816H12.8272C12.788 12.6816 12.7505 12.6972 12.7229 12.7248C12.6952 12.7525 12.6797 12.79 12.6797 12.8291V19.1677C12.6797 19.2068 12.6952 19.2443 12.7229 19.272C12.7505 19.2997 12.788 19.3152 12.8272 19.3152H14.7445C14.7836 19.3152 14.8211 19.2997 14.8488 19.272C14.8764 19.2443 14.892 19.2068 14.892 19.1677V17.1046H16.9568C16.9959 17.1046 17.0334 17.089 17.0611 17.0614C17.0887 17.0337 17.1043 16.9962 17.1043 16.9571V15.0397Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_3935_59658">
<rect width="32" height="32" rx="6" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export const WhitBitDefault = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="6" fill="white"/>
<path d="M18.5854 10.2927C18.5854 7.95122 20.4878 6.04878 22.8293 6.04878L28 6L23.7561 10.2439V25.3659H15.9512C11.7561 25.3659 8.29268 21.9512 8.29268 17.7073V14.5366H4C4 12.1951 5.90244 10.2927 8.2439 10.2927H8.29268C8.29268 7.95122 10.1951 6.04878 12.5366 6.04878H17.7073L13.4634 10.2927H18.5854Z" fill="#191A34"/>
<path d="M18.3897 14.9756C17.7555 15.6097 17.7555 16.6341 18.3897 17.2683C19.0238 17.9024 20.0482 17.9024 20.6824 17.2683L18.3897 14.9756Z" fill="white"/>
</svg>
`;


export default {
    binance,
    kraken,
    kucoin,
    okxSub,
    WhitBitDefault
}

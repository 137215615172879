import axios from 'axios';
import {API_URL} from '@/store/config';
import { handleResponseError } from "@/exception/auth.exception";

// create an axios instance
const service = axios.create({
    baseURL: API_URL,
    timeout: 30000,
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        if (localStorage.token) {
            config.headers.Authorization = 'Bearer ' + localStorage.token;
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
        }
        return config;
    },
    (error) => {
        return handleResponseError(error);
    },
);

// response interceptor
service.interceptors.response.use(
    (response) => response,
    (error) => {
        return handleResponseError(error);
    },
);

export default service;

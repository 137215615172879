export const TABLE_TH = [
    {
        name: 'ID',
        sort: '',
        noSort: false,
        isSortable: true,
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Network',
        sort: '',
        noSort: false,
        isSortable: true,
        key: 'networkId',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Address',
        sort: '',
        noSort: false,
        isSortable: true,
        key: 'addressWeb3',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Created',
        sort: '',
        noSort: false,
        isSortable: true,
        key: 'created',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Comment',
        sort: '',
        noSort: true,
        isSortable: false,
        key: 'comment',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Action',
        sort: '',
        noSort: false,
        isSortable: true,
        key: 'action',
        description: 'Trade round is the latest round of trade negotiations'
    },
];

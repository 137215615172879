<template>
  <div class="table-widget">
    <div class="table-widget_head">
      <div class="title">
        Requests by coins {{ dateFormatPicker(date[0]) }} - {{ dateFormatPicker(date[1]) }}
      </div>
      <div class="filters">
        <div class="dates_range">
          <date-picker
            v-model:value="date"
            :clearable="false"
            :editable="false"
            default-panel="day"
            separator=" - "
            value-type="format"
            :format="dateFormatUrl"
            placeholder="Reporting period"
            :month-format="'MMMM'"
            range
            input-class="dates-item"
            :class="{'dates_filled': date}"
            @change="getRateList"
          >
            {{ dateText }}
            <template #icon-calendar>
              <calendar-icon />
            </template>
          </date-picker>
        </div>
        <div class="separator" />
        <div class="filters_block">
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'hour'}"
            @click="setDateHandler('hour')"
          >
            1H
          </div>
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'last24hours'}"
            @click="setDateHandler('last24hours')"
          >
            24H
          </div>
        </div>
        <div class="separator" />
        <div class="filters_block">
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'day'}"
            @click="setDateHandler('day')"
          >
            Today
          </div>
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'yesterday'}"
            @click="setDateHandler('yesterday')"
          >
            Yesterday
          </div>
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'week'}"
            @click="setDateHandler('week')"
          >
            Week
          </div>
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'month'}"
            @click="setDateHandler('month')"
          >
            Month
          </div>
        </div>
        <div class="separator" />
        <div class="filters_block">
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'q1'}"
            @click="setDateHandler('q1')"
          >
            Q1
          </div>
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'q2'}"
            @click="setDateHandler('q2')"
          >
            Q2
          </div>
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'q3'}"
            @click="setDateHandler('q3')"
          >
            Q3
          </div>
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'q4'}"
            @click="setDateHandler('q4')"
          >
            Q4
          </div>
        </div>
        <div class="separator" />
        <div class="filters_block">
          <div
            class="filters--item"
            :class="{'filters--item-active': currentDate === 'year'}"
            @click="setDateHandler('year')"
          >
            Year
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RateService from "@/services/rate.service";
import dayjs from "dayjs";
import CalendarIcon from "@/icons/calendarIcon.vue";
import DatePicker from "vue-datepicker-next";
import SortPassive from "@/icons/sorting/sort-passive.vue";
import SortAZ from "@/icons/sorting/sort-a-z.vue";
import SortZA from "@/icons/sorting/sort-z-a.vue";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default {
  name: 'RateRequestTableNotFound',
  components: {DatePicker, CalendarIcon},
  data() {
    return {
      date: '',
      currentDate: '',
      startDate: '',
      endDate: '',
      modeDate: 'day',
      dateFormat: 'DD.MM.YYYY',
      dateFormatUrl: 'YYYY-MM-DD',
      dateFormatUrlHour: 'YYYY-MM-DD HH:mm:ss',
      loginList: null,
      dateList: null,
      sortDirections: {},
      currentSortPartner: {login: ''},
      rateListData: null,
      isLoading: false,
      currentCount: 0,
      batchSize: 50,
      canLoadMore: true,
      displayedList: [],
      displayedListDate: null,
      message: '',
    };
  },
  computed: {
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
    totalCnt() {
      return this.rateListData.reduce((accumulator, item) => accumulator + item.cnt, 0);
    }
  },
  watch: {
    currentDate(val) {
      switch (val) {
        case 'hour':
          this.date = [
            dayjs().subtract(1, 'hour').format(this.dateFormatUrlHour),
            dayjs().format(this.dateFormatUrlHour)
          ];
          break;
        case 'last24hours':
          this.date = [
            dayjs().subtract(1, 'day').format(this.dateFormatUrlHour), // ровно 24 часа назад
            dayjs().format(this.dateFormatUrlHour)
          ];
          break;
        case 'day':
          this.date = [
            dayjs().startOf('day').format(this.dateFormatUrlHour),
            dayjs().format(this.dateFormatUrlHour)
          ];
          break;
        case 'yesterday':
          this.date = [
            dayjs().subtract(1, 'day').startOf('day').format(this.dateFormatUrlHour),
            dayjs().subtract(1, 'day').endOf('day').format(this.dateFormatUrlHour)
          ];
          break;
        case 'week':
          this.date = [dayjs().startOf('isoWeek').format(this.dateFormatUrlHour), dayjs().format(this.dateFormatUrlHour)];
          break;
        case 'month':
          this.date = [dayjs().startOf('month').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'year':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'q1':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().startOf('year').add(3, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q2':
          this.date = [dayjs().startOf('year').add(3, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(6, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q3':
          this.date = [dayjs().startOf('year').add(6, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(9, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q4':
          this.date = [dayjs().startOf('year').add(9, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(11, 'month').endOf('month').format(this.dateFormatUrl)];
          break;
        default:
          break;
      }
      this.getRateList();
    }
  },
  mounted() {
    this.currentDate = 'last24hours';
  },
  methods: {
    async getRateList() {
      const query = `?start=${this.date[0]}&stop=${this.date[1]}`;
      RateService.requestByCoins(query).then((res) => {
        console.log(res);
      })
    },
    getSortIcon(partnerLogin) {
      if (partnerLogin === this.currentSortPartner.login) {
        return this.currentSortPartner.sort === 'desc' ? 'sort-z-a' : 'sort-a-z';
      }
      return 'sort-passive';
    },
    dateFormatter(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).format(this.dateFormatUrl)
    },
    dateFormatPicker(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).format(this.dateFormat)
    },
    setDateHandler(date) {
      this.currentDate = date
    },
    syncScroll(event) {
      const activeDivId = event.target.id;

      const passiveDiv = activeDivId === "div1" ? document.getElementById("div2") : document.getElementById("div1");

      passiveDiv.scrollTop = event.target.scrollTop;
      passiveDiv.scrollLeft = event.target.scrollLeft;
    },
  }
}
</script>

<style lang="scss">
.table-widget {
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 16px;
  border: 1px solid var(--grayscale-divider, #DFE0EB);

  thead {
    tr {
      position: sticky;
      top: 0;
      z-index: 99;

      .th-partner, .th-pair {
        background: white;
      }
    }
  }

  &_head {
    padding: 32px 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      color: #1B1A1C;
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }

    .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      box-sizing: border-box;

      .dates {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;

        @media (max-width: 1079px) {
          overflow: auto;
          scrollbar-width: none;
        }

        &-item {
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 500;
          background: #FCFDFE;
          border: 1px solid #F0F1F7;
          box-sizing: border-box;
          border-radius: 6px;
          color: #0A68F7;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            border: 1px solid #0A68F7;
          }
        }

        .active {
          color: #FCFDFE;
          background: #0A68F7;
        }

        &_filled {
          .mx-input-wrapper {
            input {
              width: 25ch !important;
              height: 40px;
            }
          }
        }

        &_range {
          background: none !important;

          .mx {
            &-datepicker {
              width: unset;

              &-popup, &-main {
                box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
                border-radius: 12px;
                overflow: hidden;
                border: none;
              }
            }

            &-icon-calendar, &-icon-clear {
              left: 9px;
              right: unset;
            }

            &-datepicker svg {
              fill: none;
            }

            &-input-wrapper {
              input {
                &::placeholder {
                  color: #0A68F7;
                  opacity: 1;
                }

                width: 14ch;
              }

              .dates-item {
                padding-left: 30px !important;
              }

              .mx-icon-calendar {
                svg {
                  path {
                    fill: #0A68F7;
                    fill-opacity: 100%;
                  }
                }
              }
            }
          }


          .dates-item {
            padding: 6px 8px;
          }

          &_active {
            .dates-item {
              color: #FCFDFE;
              background: #0A68F7;
            }

            .mx {
              &-icon-calendar, &-icon-clear {
              }

              &-datepicker svg path {
                fill: white !important;
              }

              &-input-wrapper {
                input {
                  &::placeholder {
                    color: white;
                    opacity: 1;
                  }

                  width: 14ch;
                }
              }
            }

            .mx-icon-calendar {
              svg {
                fill: white;
                fill-opacity: 100%;
              }
            }
          }
        }
      }

      .separator {
        width: 1px;
        height: 20px;
        background: #DFE0EB;
        border-radius: 4px;
      }

      &_block {
        display: flex;
        align-items: center;
        gap: 9px;
      }

      &--item {
        user-select: none;
        padding: 10px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0A68F7;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          border: 1px solid #0A68F7;
        }

        &-active {
          background: #0A68F7;
          border: 1px solid #0A68F7;
          color: white;
          cursor: default;
        }
      }
    }
  }

  table {
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 3px solid #DFE0EB;
      }
    }
  }

  .table {
    overflow: auto;
    scrollbar-width: none;
    margin-bottom: 0;

    &-block {
      max-height: 70vh;
      position: relative;
      box-sizing: border-box;

      tbody {
        tr {
          border-bottom: 1px solid #DFE0EB;
        }

        td {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }
      }

      .th-pair {
        color: #9FA2B4;
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.2px;
        box-sizing: border-box;
        cursor: pointer;
        word-wrap: anywhere;
      }

      .th-total {
        color: var(--grayscale-gray, #9FA2B4);
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.2px;
        box-sizing: border-box;
        text-align: right;
        padding-right: 40px;
        background: white;
        cursor: pointer;
      }

      .th-partner {
        color: var(--grayscale-gray, #9FA2B4);
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.2px;
        padding-top: 8px;
        padding-bottom: 12px;
        position: sticky;
        box-sizing: border-box;
        text-align: start;
        left: 0;

        &_text {
          padding-left: 32px;
        }
      }

      .td-pair {
        min-width: 149px;
        color: var(--black, #1B1A1C);
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        box-sizing: border-box;
        padding: 0;
      }

      .td-login {
        padding: 0 16px 0 0;
        box-sizing: border-box;
        color: var(--black, #1B1A1C);
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        min-width: 136px;
        position: sticky;

        left: 0;

        &_block {
          padding-left: 32px;
          box-sizing: border-box;
          height: 57px;
          background: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }

      .td-full-width {
        width: 100%;
      }

      .td-total {
        min-width: 136px;
        box-sizing: border-box;
        color: var(--black, #1B1A1C);
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        padding-right: 40px;
        background: white;

        &-first {
          color: var(--black, #1B1A1C);
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.2px;
          padding-left: 32px;
          padding-top: 38px;
          padding-bottom: 38px;
          position: sticky;
          bottom: 0;
          left: 0;
        }
      }

      .cell-total {
        min-width: 140px;
        color: var(--black, #1B1A1C);
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
      }

      .row-total {
        position: sticky;
        bottom: 0;
        left: 0;
        background: white;
        width: 100%;
        display: flex;
        flex-direction: column;

        .last-row {
          border-top: 1px solid #DFE0EB;
          display: flex;
          flex-direction: row;
          align-items: center;
          background: white;


          .td-login {
            min-width: 136px;
            box-sizing: border-box;
            background: white;
            padding: 0;
            height: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .cell-total {
            min-width: 149px;
            background: white;
            padding: 0;
            height: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }

          .td-total {
            height: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }
        }
        .button-row {
          position: sticky;
          width: 100%;
          display: flex;
          flex-direction: row;
          padding: 17px;

          .button {
            padding: 10px 16px;
            margin: 0 auto;
            gap: 8px;
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            background: #0A68F7;
            color: #FFF;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            cursor: pointer;
          }

        }
      }
    }
  }

  .empty-block {
    height: 500px;
  }
}
</style>

<template>
  <tr
    class="email-letters-item-tr"
  >
    <td
      class="table-id"
    >
      {{ nftItem.id }}
    </td>
    <td
      class="table-lang"
    >
      {{ nftItem.name }}
    </td>
    <td
      class="table-author"
    >
      {{ nftItem.discount }}
    </td>
    <td
      class="table-status"
    >
      <true-status v-if="+nftItem.status === 1" />
      <false-status v-else />
    </td>
    <td class="table-edit">
      <button
        class="edit"
        @click="editNft"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
            fill="#0065FF"
          />
        </svg>
      </button>
      <button
        class="delete"
        @click="deleteNft(nftItem.id)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5.8335 17.5C5.37516 17.5 4.9828 17.3368 4.65641 17.0104C4.33002 16.684 4.16683 16.2917 4.16683 15.8333V5H3.3335V3.33333H7.50016V2.5H12.5002V3.33333H16.6668V5H15.8335V15.8333C15.8335 16.2917 15.6703 16.684 15.3439 17.0104C15.0175 17.3368 14.6252 17.5 14.1668 17.5H5.8335ZM14.1668 5H5.8335V15.8333H14.1668V5ZM7.50016 14.1667H9.16683V6.66667H7.50016V14.1667ZM10.8335 14.1667H12.5002V6.66667H10.8335V14.1667Z"
            fill="#FF603D"
          />
        </svg>
      </button>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import TrueStatus from '@/icons/trueStatus.vue';
import FalseStatus from '@/icons/falseStatus.vue';

export default {
  name: "NftItem",
  components: {FalseStatus, TrueStatus},
  props: {
    // eslint-disable-next-line vue/require-prop-types
    nftItem: {
      required: true
    },
    deleteNft: {
      required: true
    }
  },
  data() {
    return {
      mainDomain: process.env.VUE_APP_MAIN_DOMAIN,
      isStatusDropdownOpen: false,
      currentStatusIndex: null
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    document.addEventListener('click', this.statusClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.statusClickOutside);
  },
  methods: {
    editNft() {
      const data = {...this.nftItem}
      this.$store.commit('setNftData', data);
    },
    dataFormatting(value, format) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(format)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

  .email-letters-item-tr {
    height: 56px;
    border-bottom: 1px solid #EAEFF4;
    transition: .3s;

    &:hover {
      background: #F5F9FF;
    }

    td {
      position: relative;
      color: $black-color1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.28px;

      span {
        color: $gray-color;
      }
    }

    td.table-id {
      padding-left: 32px;
    }

    td.table-edit {
      padding-right: max(2.62%, 32px);
      display: flex;
      height: 56px;
      gap: 6px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        outline: none;
        border: none;
        cursor: pointer;
        box-sizing: border-box;
        transition: 0.3s;
      }

      button.edit, button.link {
        justify-content: center;
        background: $light-gray-color;
      }

      button.link.disabled {
        cursor: not-allowed;

        svg path {
          fill: #A1BBD9;
        }
      }
    }
  }

</style>

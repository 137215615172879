import service from '../plugins/request';

class NftService {

    getNftAll() {
        return service.get('/api/ClientStrategy/all')
    }

    getNftById(id) {
        return service.get(`/api/ClientStrategy/${id}`)
    }

    updateNft(id,query) {
        return service.post(`/api/ClientStrategy/Edit/${id}`,query)
    }

    createNft(query) {
        return service.post(`/api/ClientStrategy/Create`,query)
    }

    deleteNft(id) {
        return service.delete(`/api/ClientStrategy/${id}`)
    }

    getNftTypes() {
        return service.get(`/api/ClientStrategy/Types`)
    }
}


export default new NftService();

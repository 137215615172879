import service from '../plugins/request';

class FakeCoinService {

    getFakeList(query) {
        return service.get('/api/fakeCoin/list' + query)
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getFakeById(id) {
        return service.get(`/api/fakeCoin/${id}`)
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    createFake(query) {
        return service.post('/api/fakeCoin/create', query)
    }

    getFakeRowById(id) {
        return service.get(`/api/fakeCoin/row/${id}`)
    }

    updateFakeRowById(id, query) {
        return service.patch(`/api/fakeCoin/row/${id}`, query)
    }

    updateFake(query) {
        return service.patch('/api/fakeCoin/row', query)
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getFakeLogsById(id) {
        return service.get(`/api/fakeCoin/${id}/Logs/`)
    }

    updateFakeStatusById(id, query) {
        return service.patch(`/api/fakeCoin/status/${id}` + query)
    }

    updateRowStatusById(rowId, query) {
        return service.patch(`/api/fakeCoin/row/status/${rowId}` + query)
    }

    addNewRulesById(itemId, query) {
        return service.post(`/api/fakeCoin/${itemId}/row/create`, query)
    }

    deleteRuleById(rowId) {
        return service.delete(`/api/fakeCoin/row/${rowId}`)
    }

    archiveFake(itemId, query) {
        return service.patch(`/api/fakeCoin/archive/${itemId}` + query)
    }

    validateFake(query) {
        return service.post('/api/fakeCoin/validate', query)
    }
}

export default new FakeCoinService();

<template>
  <AccountLayoutNew>
    <template #title>
      <NftEdit
        v-if="NftData"
        :update-list="mountList"
      />
      <div class="layout-header">
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 28 28" fill="none"><path d="M14 25.6663L3.5 19.658V8.02051L14 2.33301L24.5 8.02051V19.658L14 25.6663ZM10.675 11.1413C11.1222 10.6941 11.6326 10.3295 12.2062 10.0476C12.7799 9.76565 13.3778 9.62467 14 9.62467C14.6222 9.62467 15.2201 9.76565 15.7937 10.0476C16.3674 10.3295 16.8778 10.6941 17.325 11.1413L21.7875 8.51634L14 4.37467L6.2125 8.51634L10.675 11.1413ZM13.125 23.158V18.2872C12.1139 18.015 11.2778 17.49 10.6167 16.7122C9.95556 15.9344 9.625 15.0302 9.625 13.9997C9.625 13.7858 9.63958 13.567 9.66875 13.3434C9.69792 13.1198 9.75139 12.9011 9.82917 12.6872L5.25 9.97468V18.6372L13.125 23.158ZM14 16.6247C14.7389 16.6247 15.3611 16.3719 15.8667 15.8663C16.3722 15.3608 16.625 14.7386 16.625 13.9997C16.625 13.2608 16.3722 12.6386 15.8667 12.133C15.3611 11.6275 14.7389 11.3747 14 11.3747C13.2611 11.3747 12.6389 11.6275 12.1333 12.133C11.6278 12.6386 11.375 13.2608 11.375 13.9997C11.375 14.7386 11.6278 15.3608 12.1333 15.8663C12.6389 16.3719 13.2611 16.6247 14 16.6247ZM14.875 23.158L22.75 18.6372V9.97468L18.1708 12.6872C18.2486 12.9011 18.3021 13.1198 18.3312 13.3434C18.3604 13.567 18.375 13.7858 18.375 13.9997C18.375 15.0302 18.0444 15.9344 17.3833 16.7122C16.7222 17.49 15.8861 18.015 14.875 18.2872V23.158Z" fill="#02C076"></path></svg>
          NFT Achievements
        </h2>
      </div>
    </template>
    <template #content>
      <div
        v-if="nftListData"
        class="main-block nft-page"
      >
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="table-block">
          <div
            ref="stickyBlock"
            class="table-block-header"
            :class="{ sticky: false }"
          >
            <div class="filters-component-container">
              <div class="filters-component-container">
                <div class="table-block-header-title">
                  List of NFT
                </div>
                <button
                  class="add-new-button"
                  @click="newNft"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H11.6042L9.9375 4.16667H4.16667V15.8333H15.8333V10.0417L17.5 8.375V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM7.5 12.5V8.95833L15.1458 1.3125C15.3125 1.14583 15.5 1.02083 15.7083 0.9375C15.9167 0.854167 16.125 0.8125 16.3333 0.8125C16.5556 0.8125 16.7674 0.854167 16.9687 0.9375C17.1701 1.02083 17.3542 1.14583 17.5208 1.3125L18.6875 2.5C18.8403 2.66667 18.9583 2.85069 19.0417 3.05208C19.125 3.25347 19.1667 3.45833 19.1667 3.66667C19.1667 3.875 19.1285 4.07986 19.0521 4.28125C18.9757 4.48264 18.8542 4.66667 18.6875 4.83333L11.0417 12.5H7.5ZM9.16667 10.8333H10.3333L15.1667 6L14.5833 5.41667L13.9792 4.83333L9.16667 9.64583V10.8333Z"
                      fill="white"
                    />
                  </svg>
                  Create new
                </button>
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <Transition name="committee">
              <table class="table">
                <thead>
                  <tr class="table-headers">
                    <th
                      v-for="(item, index) in tableTh"
                      :id="item.id"
                      :key="index"
                      scope="col"
                      :class="'table-' + item.key"
                    >
                      <div
                        class="text"
                        @click="sortData(item)"
                        @mouseover="tokenTo = index"
                        @mouseleave="tokenTo = null"
                      >
                        {{ item.name }}
                        <span v-if="item.isSortable">
                          <sort-passive v-if="!item.sort" />
                          <sort-a-z v-if="item.sort === 'ASC'" />
                          <sort-z-a v-if="item.sort === 'DESC'" />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="nftListData.length">
                  <NftItem
                    v-for="nftItem in nftListData"
                    :key="nftItem.id"
                    :nft-item="nftItem"
                    :deleteNft="deleteNftHandler"
                  />
                </tbody>
              </table>
            </Transition>
            <Transition name="committee">
              <no-data-block v-if="!nftListData.length && !isLoading" />
            </Transition>
          </div>
        </div>
      </div>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Loading from 'vue-loading-overlay';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import SortZA from '@/icons/sorting/sort-z-a.vue';
import { TABLE_TH} from '@/pages/nft/nftData';
import filterMixin from '@/mixins/filter.mixin';
import helperMixin from '@/mixins/helper.mixin';
import NoDataBlock from '@/components/admin/NoDataBlock.vue';
import EmailLettersService from '@/services/email-letters.service';
import NftItem from '@/pages/nft/partials/NftItem.vue';
import NftService from '@/services/nft.service';
import NftEdit from '@/pages/nft/partials/NftEditor.vue';
import {mapGetters} from 'vuex';
import UserService from '@/services/user.service';

export default defineComponent({
  components: {
    NftEdit,
    NftItem,
    NoDataBlock,
    SortZA, SortPassive, SortAZ, Loading, AccountLayoutNew},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      nftListData: [],
      tableTh: TABLE_TH,
      isLoading: true,
      // sticky header
      isSticky: false,
    }
  },
  computed: {
  ...mapGetters({
      NftData: 'getNftData',
      Notification: 'getNotification',
    }),
  },

  mounted() {
    this.mountList()
  },
  beforeUnmount() {

  },
  methods: {
    newNft() {
      this.$store.commit('setNftData', {newNft: true});
    },
    mountList() {
      this.isLoading = true;
      NftService.getNftAll().then((result) => {
        if (result.status === 200) {
          this.nftListData = result.data.result.sort((a, b) => a.id - b.id);
          this.isLoading = false;
        }
      }).catch((error) => {
        console.log(error.response.data.result, 'get nft error')
      })
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    deleteNftHandler(id) {
      this.setNotification({name: 'Deleting NFT', value: 'ID: ' + id});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          NftService.deleteNft(id).then((result) => {
            if (result.status === 200) {
              this.mountList();
              this.isLoading = true;
            }
          }).catch((error) => {
            console.log(error.response.data.result, 'delete nft error')
          })
        }
      });
    },
    // sort
    sortData(item) {

    },
  }
})
</script>

<style scoped lang="scss">
.nft-page {
  .table-block-header {
    flex-direction: row;
    @media (max-width: 1140px) {
      flex-direction: column !important;
    }
  }

  .table-block-table table {
    @media (max-width: 1079px) {
      width: 100%;
    }
  }

  tr.table-headers th.table-id {
    padding-left: 32px;
    width: 40px;
  }

  tr.table-headers th.table-edit {
    padding-right: 32px;
    width: 40px;
  }


  .filters-component-container {
    width: 100%;

    .add-new-button {
      display: inline-flex;
      padding: 6px 12px 6px 6px;
      align-items: flex-start;
      gap: 4px;
      color: white;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-style: normal;
      height: 32px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 8px;
      background: #0065FF;
      outline: none;
      border: none;
      cursor: pointer;
      box-sizing: border-box;
      transition: 0.3s;
    }
  }

  .table-block-header.sticky {
    position: fixed;
    top: 0;
    background: #fff;
    width: calc(100% - 385px);
    z-index: 10;

    @media (max-width: 1079px) {
      left: 0;
      width: calc(100% - 32px);
    }
  }

  .table-block-header-title {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 32px;
    min-width: fit-content !important;
  }
}
</style>

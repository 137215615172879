import service from '../plugins/request';

class LangService {

    getLanguages() {
        return service.get('/api/Language/Codes')
    }
}


export default new LangService();

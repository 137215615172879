<script>
export default {
  name: "NftIcon"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 28 28" fill="none"><path d="M14 25.6663L3.5 19.658V8.02051L14 2.33301L24.5 8.02051V19.658L14 25.6663ZM10.675 11.1413C11.1222 10.6941 11.6326 10.3295 12.2062 10.0476C12.7799 9.76565 13.3778 9.62467 14 9.62467C14.6222 9.62467 15.2201 9.76565 15.7937 10.0476C16.3674 10.3295 16.8778 10.6941 17.325 11.1413L21.7875 8.51634L14 4.37467L6.2125 8.51634L10.675 11.1413ZM13.125 23.158V18.2872C12.1139 18.015 11.2778 17.49 10.6167 16.7122C9.95556 15.9344 9.625 15.0302 9.625 13.9997C9.625 13.7858 9.63958 13.567 9.66875 13.3434C9.69792 13.1198 9.75139 12.9011 9.82917 12.6872L5.25 9.97468V18.6372L13.125 23.158ZM14 16.6247C14.7389 16.6247 15.3611 16.3719 15.8667 15.8663C16.3722 15.3608 16.625 14.7386 16.625 13.9997C16.625 13.2608 16.3722 12.6386 15.8667 12.133C15.3611 11.6275 14.7389 11.3747 14 11.3747C13.2611 11.3747 12.6389 11.6275 12.1333 12.133C11.6278 12.6386 11.375 13.2608 11.375 13.9997C11.375 14.7386 11.6278 15.3608 12.1333 15.8663C12.6389 16.3719 13.2611 16.6247 14 16.6247ZM14.875 23.158L22.75 18.6372V9.97468L18.1708 12.6872C18.2486 12.9011 18.3021 13.1198 18.3312 13.3434C18.3604 13.567 18.375 13.7858 18.375 13.9997C18.375 15.0302 18.0444 15.9344 17.3833 16.7122C16.7222 17.49 15.8861 18.015 14.875 18.2872V23.158Z" fill="#02C076"></path></svg>
</template>

<style scoped lang="scss">

</style>

<template>
  <div

    ref="modalOverlay"
    class="nftModal"
    tabindex="0"
    @click="modalOverlayClose"
  >
    <div
      v-if="nftformData"
      class="nftModal-form"
    >
      <div class="nftModal-form-head">
        <div class="nftModal-form-title">
          {{ nftformData.newNft === true ? 'Create NFT' : 'Edit NFT' }}
        </div>
        <div class="nftModal-form-close">
          <close-icon @click="closeModal" />
        </div>
      </div>
      <div class="nftModal-form-inputs">
        <div class="nftModal-form-inputs-item">
          <div class="desc">
            Name
          </div>
          <input
            v-model="nftformData.name"
            type="text"
          >
        </div>
        <div class="nftModal-form-inputs-item">
          <div class="desc">
            Description
          </div>
          <textarea
            ref="textarea"
            v-model="nftformData.description"
            @input="autoResize"
          />
        </div>
        <div class="nftModal-form-inputs-item">
          <div class="desc">
            Done description
          </div>
          <textarea
            ref="textarea"
            v-model="nftformData.doneDescription"
            @input="autoResize"
          />
        </div>
        <div v-if="!nftformData.newNft && langOptions.length !== 0" class="nftModal-form-inputs-item">
          <div class="desc">
            Locales
          </div>
          <CustomSelect
            :options="langOptions"
            :value-input="selectedLang"
            @change-select="({option}) => selectedLang = option"
          />
          <template v-if="selectedLang">
            <div class="desc">
              Description
            </div>
            <textarea
              ref="textarea"
              v-model="descValue"
              style="margin-bottom: 32px"
              @input="autoResize"
            />
            <div class="desc">
              Done description
            </div>
            <textarea
              ref="textarea"
              v-model="doneDescValue"
              @input="autoResize"
            />
          </template>
        </div>
        <div v-if="typesOptions" class="nftModal-form-inputs-item">
          <div class="desc">
            Type
          </div>
          <CustomSelect
            v-if="nftformData.newNft"
            :options="typesOptions"
            :value-input="nftformData.type"
            @change-select="({option}) => nftformData.type = option"
          />
          <span v-else>{{ nftformData.type }}</span>
        </div>
        <div class="nftModal-form-inputs-item">
          <div class="desc">
            Volume
          </div>
          <input
            v-model="nftformData.volume"
            type="text"
          >
        </div>
        <div class="nftModal-form-inputs-item">
          <div class="desc">
            Position
          </div>
          <input
            v-model="nftformData.position"
            type="number"
          >
        </div>
        <div class="nftModal-form-inputs-item">
          <div class="desc">
            Market URL
          </div>
          <input
            v-model="nftformData.marketURL"
            type="text"
          >
        </div>
        <!--    1    -->
        <div v-if="!nftformData.newNft" class="nftModal-form-inputs-item">
          <div class="desc">
            Image 1x
          </div>
          <input
            id="image"
            ref="uploadFile"
            type="file"
            @change="uploadFile($event,'1x')"
          >
          <div
            class="coin-change"
            @click="$refs.uploadFile.click()"
          />
        </div>
        <!--    2    -->
        <div v-if="!nftformData.newNft" class="nftModal-form-inputs-item">
          <div class="desc">
            Image 2x
          </div>
          <input
            id="image"
            ref="uploadFile2x"
            type="file"
            @change="uploadFile($event,'2x')"
          >
          <div
            class="coin-change"
            @click="$refs.uploadFile2x.click()"
          />
        </div>
        <!--    4    -->
        <div v-if="!nftformData.newNft" class="nftModal-form-inputs-item">
          <div class="desc">
            Image 4x
          </div>
          <input
            id="image"
            ref="uploadFile4x"
            type="file"
            @change="uploadFile($event,'4x')"
          >
          <div
            class="coin-change"
            @click="$refs.uploadFile4x.click()"
          />
        </div>
        <div class="nftModal-form-inputs-item">
          <div class="desc">
            Discount
          </div>
          <input
            v-model="nftformData.discount"
            type="text"
            @input="onDiscountInput"
          >
        </div>
        <div class="toggles">
          <div class="toggles-item">
            <div class="text">
              Active
            </div>
            <Toggle
              v-model="nftformData.status"
              :true-value="1"
              :false-value="0"
            />
          </div>

          <div class="toggles-item">
            <div class="text">
              Discount label
            </div>
            <Toggle
              v-model="nftformData.isDiscount"
            />
          </div>

          <div class="toggles-item">
            <div class="text">
              NFT label
            </div>
            <Toggle
              v-model="nftformData.isNFT"
            />
          </div>

          <div class="toggles-item">
            <div class="text">
              Cover label
            </div>
            <Toggle v-model="nftformData.isCover" />
          </div>
        </div>
      </div>
      <div class="nftModal-form-buttons">
        <div
          class="nftModal-form-buttons-item cancel"
          @click="closeModal"
        >
          Cancel
        </div>
        <div
          class="nftModal-form-buttons-item submit"
          @click="updateSettings"
        >
          Save
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "../../../icons/closeIcon";
import Toggle from '@vueform/toggle'
import {nextTick} from 'vue';
import FileService from '@/services/file.service';
import NftService from '@/services/nft.service';
import {MAIN_DOMAIN} from '@/store/config';
import CustomSelect from '@/pages/nft/partials/CustomSelect.vue';
import {mapGetters} from 'vuex';
import LangService from '@/services/lang.service';

export default {
  name: "NftEdit",
  components: {CustomSelect, Toggle, CloseIcon},
  props: {
    updateList: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      domain: MAIN_DOMAIN,
      nftformData: null,
      chosenFile: null,
      typesOptions: [],
      langOptions: [],
      selectedLang: '',
      resolution: 'x1',
      descValue: "",
      doneDescValue: ""
    }
  },
  computed: {
    currentDescObj() {
      // Благодаря ?. (опциональная цепочка), если nftformData или languages === null, не будет ошибки
      return this.nftformData?.languages?.find(
        (item) =>
          item.langCode === this.selectedLang &&
          item.key.endsWith("_Description")
      ) || null;
    },
    currentDoneDescObj() {
      return this.nftformData?.languages?.find(
        (item) =>
          item.langCode === this.selectedLang &&
          item.key.endsWith("_DoneDescription")
      ) || null;
    }
  },
  watch: {
    currentDescObj(newVal) {
      // При смене currentDescObj (то есть языка или массива) заполняем текст
      this.descValue = newVal?.template || "";
    },
    currentDoneDescObj(newVal) {
      // Аналогично для DoneDescription
      this.doneDescValue = newVal?.template || "";
    },

    // Если меняем текст, синхронизируем с массивом
    descValue(newVal) {
      if (this.currentDescObj) {
        this.currentDescObj.template = newVal;
      }
    },
    doneDescValue(newVal) {
      if (this.currentDoneDescObj) {
        this.currentDoneDescObj.template = newVal;
      }
    }
  },
  mounted() {
    if (this.$store.state.nftData.newNft) {
      this.nftformData = {
        name: '',
        description: '',
        discount: 0,
        imageUrl: null,
        marketURL:null,
        volume: null,
        type: null,
        status: 0,
        isDiscount: false,
        isNFT: false,
        isCover: false,
        newNft: true,
        doneDescription: ''
      }
      NftService.getNftTypes().then((res) => {
        this.typesOptions = res.data.result
      }).catch(() => {

      });
    } else {
      LangService.getLanguages().then((res) => {
        if (res) {
          this.langOptions = res.data.result
        }
      });
      NftService.getNftById(this.$store.state.nftData.id).then((res) => {
        this.nftformData = res.data.result
      }).catch(() => {
        console.log('Error get nft by ID')
      });
    }

    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.$store.commit('setNftData', null);
      }
    });
    this.autoResize()
  },
  beforeUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.$store.commit('setNftData', null);
      }
    })
  },
  methods: {
    uploadFile(e,res) {
      this.resolution = res;
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }
      //this.chosenFile = URL.createObjectURL(files[0]);
      this.addImage(files[0]);
    },
    addImage(file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.file = file;
        this.uploadImage();
      };
      reader.readAsBinaryString(file);

    },
    uploadImage() {
      const query = {
        FileName: this.nftformData.id + '-' + this.resolution,
        FolderName: this.nftformData.id+'',
        UploadFile: this.file
      }

      FileService.uploadNftImage(query).then((result) => {
        if (this.resolution === '1x') {
          this.nftformData.imageUrl = result.data.result;
        }
      }).catch((error) => {
        console.error(error);
      })
    },
    onDiscountInput(event) {
      let value = event.target.value.replace(/\D/g, '');
      if (value && Number(value) > 100) {
        value = '100';
      }
      this.nftformData.discount = value;
    },
    closeModal() {
      this.nftformData = null;
      this.$store.commit('setNftData', null);
    },
    updateSettings() {
      if (this.nftformData.newNft) {
        NftService.createNft(this.nftformData).then(() => {
          this.successPopup();
          this.closeModal();
          this.updateList();
        }).catch(() => {
          this.errorPopup();
        });
      } else {
        this.nftformData.languages = this.nftformData.languages.filter(item => item.template !== null)
        NftService.updateNft(this.nftformData.id, this.nftformData).then(() => {
          this.successPopup();
          this.closeModal();
          this.updateList();
        }).catch(() => {
          this.errorPopup();
        });
      }
    },
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'NFT edit success',
        value: this.nftData,
      });
    },
    errorPopup() {
      this.$store.commit('setError', {
        name: 'NFT edit error',
        value: this.nftData,
      });
    },
    modalOverlayClose(event) {
      if (event.target === this.$refs.modalOverlay) {
        this.closeModal();
      }
    },
    autoResize() {
      nextTick(() => {
        const textarea = this.$refs.textarea;
        if (textarea) {
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + 'px';
        }
      });
    },
  }
}
</script>

<style lang="scss">
.nftModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: 100vh;
  z-index: 101;
  background: rgba(67, 40, 164, 0.54);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  overflow: auto;

  @media (max-width: 1079px) {
    height: 100%;
    z-index: 101;
  }

  &-form {
    box-shadow: 0 6px 24px rgba(27, 26, 28, 0.16);
    margin: 58px auto;
    max-width: 400px;
    position: relative;
    background: white;
    padding: 24px;
    border-radius: 16px;

    @media (max-width: 1079px) {
      margin: 0;
      height: 100vh;
      padding-bottom: 20px;
      border-radius: 0;
      overflow: auto;
      flex-direction: column;
    }

    &-title {
      font-size: 24px;
      line-height: 28px;
      color: #1B1A1C;
      font-weight: 700;
      margin-bottom: 24px;
    }

    &-close {
      position: absolute;
      right: 22px;
      top: 12px;
    }

    &-inputs {
      &-item {
        width: 100%;
        margin-bottom: 24px;

        @media (max-width: 1079px) {
          max-width: 100%;
        }

        .desc {
          font-size: 14px;
          line-height: 16px;
          color: #0A68F7;
          margin-bottom: 8px;
        }

        .chosen-img {
          width: 140px;
        }

        .disabled {
          background: #FBFBFC;
          border: 1px solid #DFE0EB;
        }

        input {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;
          padding: 11px 5px 11px 16px;
          box-sizing: border-box;
        }

        textarea {
          margin-bottom: -12px;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;
          padding: 11px 5px 11px 16px;
          box-sizing: border-box;
          resize: none;
          overflow: hidden;

          &:focus {
            outline: 1.5px solid #0A68F7;
            color: #1B1A1C;
          }
        }
      }
      .toggles {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin: 24px 0;

        &-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 15px 0;

          @media (max-width: 1079px) {
            max-width: 100%;
          }

          .text {
            font-size: 14px;
            line-height: 18px;
            color: #1B1A1C;
            font-weight: 700;
          }

          .toggle {
            width: var(--toggle-width, 34px);
            height: var(--toggle-height, 12px);
            outline: none;

            &-container {
              &:focus {
                box-shadow: none;
              }
            }
          }

          .toggle-on {
            background: var(--toggle-bg-on, rgba(10, 104, 247, 0.32));
            border-color: transparent;
          }

          .toggle-handle-on {
            transform: translateX(-91%);
            background: #0A68F7;
            box-shadow: 0 1px 2px rgba(27, 26, 28, 0.16);;
          }

          .toggle-handle-off {
            left: -2px;
          }

          .toggle-handle {
            width: 20px;
            height: 20px;
            top: -3.5px;
            box-shadow: 0 1px 1px rgba(27, 26, 28, 0.14), 0 1px 3px rgba(27, 26, 28, 0.2);
          }
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 24px;

      @media (max-width: 1079px) {
        position: absolute;
        bottom: 40px;
      }

      &-item {
        outline: none;
        border: none;
        padding: 18px 60px;
        cursor: pointer;
      }

      .cancel {
        background: none;
        color: #0A68F7;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        border-radius: 8px;

        &:hover {
          background: rgba(27, 26, 28, 0.03);
          transition: 0.3s ease-in-out;
        }
      }

      .submit {
        background: #0A68F7;
        border-radius: 8px;
        color: white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        &:hover {
          opacity: 0.9;
          transition: 0.3s ease-in-out;
        }
      }
    }

  }
}

</style>

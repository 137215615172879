<template>
  <date-picker
    v-model:value="date"
    placeholder="Dates"
    :clearable="false"
    :editable="false"
    default-panel="day"
    separator=" - "
    value-type="format"
    :format="'YYYY.MM.DD'"
    input-class="dates-input"
    range
    @change="onDateChange"
  >
    <template #footer="{ emit }">
      <div class="buttons-container">
        <div @click="setDate('today',emit)">
          Today
        </div>
        <div @click="setDate('24h',emit)">
          24H
        </div>
        <div @click="setDate('yesterday',emit)">
          Yesterday
        </div>
        <div @click="setDate('week',emit)">
          Week
        </div>
        <div @click="setDate('30d',emit)">
          30d
        </div>
        <div @click="setDate('month',emit)">
          Month
        </div>
        <div @click="setDate('q1',emit)">
          Q1
        </div>
        <div @click="setDate('q2',emit)">
          Q2
        </div>
        <div @click="setDate('q3',emit)">
          Q3
        </div>
        <div @click="setDate('q4',emit)">
          Q4
        </div>
        <div @click="setDate('year',emit)">
          Year
        </div>
      </div>
    </template>
  </date-picker>
</template>

<script>

import DatePicker from 'vue-datepicker-next';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(isoWeek);
export default {
  name: "DatesFilter",
  components: {
    DatePicker,
  },
  props: {
    isDatesOpen: {
      type: Boolean,
      required: true
    },
  },
emits: ['dateHandler'],
  data() {
    return {
      date: '',
      dateFormatUrl: 'YYYY.MM.DDTHH:mm:ss',
      dateFormat: 'DD.MM.YYYY',
      isSettingDate: false,
    }
  },
  methods: {
    onDateChange(newDate) {
      if (this.isSettingDate) {
        this.isSettingDate = false;
        return;
      }
      const result = [newDate[0]+'T00:00:00', newDate[1]+'T23:59:59'];
      this.$emit('dateHandler', result);
      this.date = '';
    },
    setDate(dateByButton, emit) {
      let pickedDate = '';
      dayjs.extend(utc)
      dayjs.extend(timezone)

      switch (dateByButton) {
        case 'today':
          pickedDate = [dayjs().startOf('day').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case '24h':
          pickedDate = [
            dayjs().subtract(24, 'hours').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'yesterday':
          pickedDate = [
            dayjs().subtract(1, 'day').startOf('day').format(this.dateFormatUrl),
            dayjs().subtract(1, 'day').endOf('day').format(this.dateFormatUrl)   
          ];
          break;
        case 'week':
          pickedDate = [dayjs().startOf('isoWeek').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case '30d':
          pickedDate = [
            dayjs().subtract(30, 'days').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'month':
          pickedDate = [dayjs().startOf('month').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'year':
          pickedDate = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'q1':
          pickedDate = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().startOf('year').add(3, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q2':
          pickedDate = [dayjs().startOf('year').add(3, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(6, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q3':
          pickedDate = [dayjs().startOf('year').add(6, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(9, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q4':
          pickedDate = [dayjs().startOf('year').add(9, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(11, 'month').endOf('month').format(this.dateFormatUrl)];
          break;
        default:
          break;
      }

      this.isSettingDate = true;
      this.date = '';
      emit('submit');
      this.$emit('dateHandler', pickedDate);
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.mx-datepicker-range {
  max-width: 76px;
}

.mx-datepicker-range::v-deep .dates-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  width: 78px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  background: url("../../assets/img/icons/dates-icon.svg") no-repeat left 6px center,$light-gray-color;
  transition: all .3s;

  &:hover {
    background: url("../../assets/img/icons/dates-icon.svg") no-repeat left 6px center,$light-gray-hover-color
  }

  &::placeholder {
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    color: $blue-color !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    opacity: 1;
  }
}

.mx-datepicker-range::v-deep .mx-icon-calendar {
  display: none;
}

.mx-calendar-range::v-deep .mx-datepicker-content {
  background: red;
}

.buttons-container {
  display: flex;
  padding-bottom: 10px;
  gap: 4px;

  div {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 6px 8px;
    max-width: 76px;
    font-size: 12px;
    color: $blue-color;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    background: $light-gray-color;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: $light-gray-hover-color
    }
  }
}
</style>

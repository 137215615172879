<template>
  <AccountLayout>
    <template #title>
      <h2>List of users</h2>
    </template>
    <template #content>
      <AddPartner v-if="IsPartnerModal" :updateList="getUsersDataList" />
      <AddAdmin v-if="IsAdminModal" :updateList="getUsersDataList" />
      <div
        class="usersList main-block"
        :class="{'popup-active': Profile || UserItem || IpList}"
      >
        <loading
          v-if="!usersData && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div
          class="table-block"
        >
          <div class="table-block-header">
            <div class="table-block-header-title">
              All users
            </div>
            <div class="table-block-header-filters">
              <div class="table-block-header-filters-search">
                <input
                  type="text"
                  placeholder="Login search"
                  @keyup="searchLogin"
                >
              </div>
              <div
                v-if="!isMobile()"
                class="table-block-header-filters-delimeter"
              />
              <div
                class="table-block-header-filters-add"
                @click="isAddMenu"
              >
                <div class="table-block-header-filters-add-btn">
                  <add-icon class="add" />
                  Add
                </div>
                <div
                  v-if="isAdd"
                  class="table-block-header-filters-add-dropdown"
                >
                  <div
                    class="item admin"
                    @click="openAdminModal"
                  >
                    Add admin
                  </div>
                  <div
                    class="item partner"
                    @click="openPartnerModal"
                  >
                    Add partner
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{
                      'table-id': item.key === 'id',
                      'table-fav': item.key === 'fav',
                      'table-login': item.key === 'login',
                      'table-role': item.key === 'role',
                      'table-createDate': item.key === 'createDate',
                      'table-program': item.key === 'program',
                      'table-status': item.key === 'active',
                      // 'table-ip': item.key === 'ipList',
                      'table-info': item.key === 'info',
                    }"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort && !item.noSort" />
                      <sort-a-z v-if="!item.noSort && item.sort === 'asc'" />
                      <sort-z-a v-if="!item.noSort && item.sort === 'desc'" />
                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="table-buttons"
                  />
                </tr>
              </thead>
              <tbody>
                <UserListItem
                  v-if="usersData && usersData.length"
                  :users-list="usersData"
                  @change-fav="handleChangeFav"
                />
              </tbody>
            </table>
            <no-data-block v-if="!usersData || !usersData.length && !isLoading" />
          </div>
        </div>
        <paged-pagination
          v-if="usersData && usersData.length && !isSearch"
          :total="batch.length"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
        <ProfilePopUp v-if="Profile" />
        <ChangePassPopUp v-if="UserItem" />
        <user-ip-list v-if="IpList" />
        <edit-user-pop-up
          v-if="userData"
          :data="userData"
          @change-fav="handleChangeFav"
        />
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import UserListItem from "./partials/UserListItem";
import {mapGetters} from 'vuex';
import ChangePassPopUp from "./partials/ChangePassPopUp";
import ProfilePopUp from "./partials/ProfilePopUp";
import HoverText from "../coins/partials/hoverText";
import filterMixin from "../../mixins/filter.mixin";
import helperMixin from "../../mixins/helper.mixin";
import AddIcon from "../../icons/addIcon";
import SortZA from "../../icons/sorting/sort-z-a";
import SortAZ from "../../icons/sorting/sort-a-z";
import SortPassive from "../../icons/sorting/sort-passive";
import {TABLE_TH} from "@/pages/users/userData";
import PagedPagination from "@/components/admin/PagedPagiantion.vue";
import UserIpList from "@/pages/users/partials/UserIpList";
import Loading from 'vue-loading-overlay';
import EditUserPopUp from "@/pages/users/partials/EditUserPopUp";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import AddPartner from '@/pages/users/partials/AddPartner.vue';
import AddAdmin from '@/pages/users/partials/AddAdmin.vue';

export default {
  name: "UsersList",
  components: {
    AddAdmin, AddPartner,
    NoDataBlock,
    EditUserPopUp,
    UserIpList,
    PagedPagination,
    SortPassive,
    SortAZ,
    SortZA,
    AddIcon,
    HoverText,
    ProfilePopUp,
    ChangePassPopUp,
    UserListItem,
    AccountLayout,
    Loading
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      search: null,
      originUsers: null,
      currentSorting: 'id',
      isAdd: false,
      batch: [],
      page: 1,
      usersData: [],
      options: {
        chunk: 4
      },
      currentPerPage: 20,
      currentIndex: null,
      tableTh: TABLE_TH,
      isLoading: false,
      fullPage: true,
      userData: null,
      isSearch: false
    }
  },
  computed: {
    ...mapGetters({
      UserItem: 'getUserItem',
      Profile: 'getProfile',
      IpList: 'getIpList',
      IsAdminModal: 'getIsAdminModal',
      IsPartnerModal: 'getIsPartnerModal',
    }),
    Users: {
      get() {
        return this.$store.state.users;
      },
      set(value) {
        this.$store.commit('setUsers', value);
      }
    },
  },
  mounted() {
    this.getUsersDataList();
    this.emitter.on('edit-user-popup', (data) => {
      this.userData = data
    })
  },
  methods: {
    handleChangeFav() {
      this.getUsersDataList();
    },
    getUsersDataList() {
      this.isLoading = true;

      this.$store.dispatch('getUsersList').then(() => {
        this.originUsers = [...this.Users];
        this.batch = this.batchDataNew(this.Users, this.currentPerPage);

        this.page = 1;
        this.usersData = this.batch[0];
        this.isLoading = false;
        this.isLoading = false;
      });
    },
    batchDataNew(data, perPage) {
      return data.map((e, i) => {
        return i % perPage === 0 ? data.slice(i, i + perPage) : null;
      }).filter(e => e);
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'id':
          this.Users = this.Users.sort((a, b) => {
            return type.sort === 'asc' ? a.id - b.id : b.id - a.id;
          });
          break;
        case 'login':
          this.Users = this.Users.sort((a, b) => {
            const aCount = a.login.localeCompare(b.login);
            const bCount = b.login.localeCompare(a.login);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;
        case 'role':
          this.Users = this.Users.sort((a, b) => {
            const aCount = a.role.localeCompare(b.role);
            const bCount = b.role.localeCompare(a.role);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;
        case 'fav':
          this.Users = this.Users.sort((a, b) => {
            return type.sort === 'asc' ? b.isFavorite - a.isFavorite : a.isFavorite - b.isFavorite;
          });
          break;
        case 'active':
          this.Users = this.Users.sort((a, b) => {
            return type.sort === 'asc' ? a.isActive - b.isActive : b.isActive - a.isActive;
          });
          break;
        case 'createDate':
          this.Users = this.Users.sort((a, b) => {
            const aDate = Date.parse(a.createUser);
            const bDate = Date.parse(b.createUser);

            return type.sort === 'asc' ? aDate - bDate : bDate - aDate;
          });
          break;
      }
      this.usersData = this.batchData(this.Users, this.currentPerPage).current;
    },
    isAddMenu() {
      this.isAdd = !this.isAdd;
    },
    searchLogin(event) {
      const value = event.target.value.toLowerCase();
      this.Users = this.originUsers;

      if (value.length) {
        this.Users = this.Users.filter((item) => item.login.toLowerCase().includes(value));
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }

      this.updatePages();
    },
    updatePages() {
      const batch = this.batchData(this.Users, this.currentPerPage);
      this.usersData = batch.current;
      this.batch = batch.total;
      this.page = 0;
    },

    pageHandler(page) {
      this.usersData = this.batch[page - 1];
    },
    openAdminModal() {
      this.$store.commit('setIsAdminModal', true);
    },
    openPartnerModal() {
      this.$store.commit('setIsPartnerModal', true);
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/components/search";
@import "../../assets/styles/pages/users/users";
</style>

<template>
  <tr class="table-row" :class="{'row-color-grey': currentStatus === 0}">
    <td class="table-cell table-id">
      <router-link :to="{ name: 'Exchanges', query: { clientId: clientUser.id } }">
        {{ clientUser.id }}
      </router-link>
    </td>
    <td class="table-cell table-createDate">
      {{ dateFormatter(clientUser.createDate) }}
    </td>
    <td class="table-cell table-email">
      <div class="email-container">
        <svg
          v-if="clientUser.isEmailVerified === true && clientUser.email"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.24"
            d="M12 21C10.755 21 9.585 20.7638 8.49 20.2912C7.395 19.8187 6.4425 19.1775 5.6325 18.3675C4.8225 17.5575 4.18125 16.605 3.70875 15.51C3.23625 14.415 3 13.245 3 12C3 10.755 3.23625 9.585 3.70875 8.49C4.18125 7.395 4.8225 6.4425 5.6325 5.6325C6.4425 4.8225 7.395 4.18125 8.49 3.70875C9.585 3.23625 10.755 3 12 3C13.245 3 14.415 3.23625 15.51 3.70875C16.605 4.18125 17.5575 4.8225 18.3675 5.6325C19.1775 6.4425 19.8187 7.395 20.2912 8.49C20.7638 9.585 21 10.755 21 12C21 13.245 20.7638 14.415 20.2912 15.51C19.8187 16.605 19.1775 17.5575 18.3675 18.3675C17.5575 19.1775 16.605 19.8187 15.51 20.2912C14.415 20.7638 13.245 21 12 21Z"
            fill="#02C076"
          />
          <path
            d="M10.9508 15.55L16.6008 9.89998L15.1758 8.47498L10.9508 12.7L8.85078 10.6L7.42578 12.025L10.9508 15.55Z"
            fill="#02C076"
          />
        </svg>
        <svg
          v-if="clientUser.isEmailVerified === false && clientUser.email"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.24"
            d="M12 21C10.755 21 9.585 20.7638 8.49 20.2912C7.395 19.8187 6.4425 19.1775 5.6325 18.3675C4.8225 17.5575 4.18125 16.605 3.70875 15.51C3.23625 14.415 3 13.245 3 12C3 10.755 3.23625 9.585 3.70875 8.49C4.18125 7.395 4.8225 6.4425 5.6325 5.6325C6.4425 4.8225 7.395 4.18125 8.49 3.70875C9.585 3.23625 10.755 3 12 3C13.245 3 14.415 3.23625 15.51 3.70875C16.605 4.18125 17.5575 4.8225 18.3675 5.6325C19.1775 6.4425 19.8187 7.395 20.2912 8.49C20.7638 9.585 21 10.755 21 12C21 13.245 20.7638 14.415 20.2912 15.51C19.8187 16.605 19.1775 17.5575 18.3675 18.3675C17.5575 19.1775 16.605 19.8187 15.51 20.2912C14.415 20.7638 13.245 21 12 21Z"
            fill="#A1BBD9"
          />
          <path
            d="M9.5 12C9.5 12.8284 8.82843 13.5 8 13.5C7.17157 13.5 6.5 12.8284 6.5 12C6.5 11.1716 7.17157 10.5 8 10.5C8.82843 10.5 9.5 11.1716 9.5 12Z"
            fill="#A1BBD9"
          />
          <path
            d="M13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12Z"
            fill="#A1BBD9"
          />
          <path
            d="M17.5 12C17.5 12.8284 16.8284 13.5 16 13.5C15.1716 13.5 14.5 12.8284 14.5 12C14.5 11.1716 15.1716 10.5 16 10.5C16.8284 10.5 17.5 11.1716 17.5 12Z"
            fill="#A1BBD9"
          />
        </svg>
        <span class="email-text">{{ clientUser.email ? clientUser.email : '—' }}</span>
      </div>
    </td>
    <td class="table-cell table-address">
      {{ clientUser.walletAddress ? web3AddressFormatter(clientUser.walletAddress) : '—' }}
    </td>
    <td class="table-cell table-name">
      {{ clientUser.name ? clientUser.name : '—' }}
    </td>
    <td class="table-cell table-order">
      <span>{{ clientUser.statsRecord ? clientUser.statsRecord.ordersCount : 0 }}</span>/<span style="color: #00B18B">{{ clientUser.statsRecord ? clientUser.statsRecord.ordersCompleted : 0 }}</span>
    </td>
    <td class="table-cell table-nft">
      <span
        v-if="clientUser.achivements.length"
        ref="nftDropdown"
        @click.stop="nftDropdownHandler()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_5749_4058)">
            <path
              opacity="0.24"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2L19.97 6.43L15.7916 8.74586C14.8735 7.67798 13.5136 7 12 7C10.4864 7 9.12652 7.67798 8.20845 8.74586L4.03 6.43L12 2ZM7.23295 10.4916L3 8.14V17L11 21.44V16.8993C8.72203 16.4341 7 14.4123 7 12C7 11.4747 7.08167 10.9679 7.23295 10.4916ZM13 16.8993V21.44L21 17V8.14L16.767 10.4916C16.9183 10.9679 17 11.4747 17 12C17 14.4123 15.278 16.4341 13 16.8993Z"
              fill="#02C076"
            />
            <path
              d="M12 9C10.35 9 9 10.35 9 12C9 13.65 10.35 15 12 15C13.65 15 15 13.65 15 12C15 10.35 13.65 9 12 9Z"
              fill="#02C076"
            />
          </g>
          <defs>
            <clipPath id="clip0_5749_4058">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <div
          class="dropdown"
          v-if="isNftOpen"
          :class="{'position-top': positionOfDropdown === 'top'}"
        >
          <div class="dropdown-item" v-for="(item, index) in clientUser.achivements" :key="index">{{ item.name }}</div>
        </div>
      </span>
    </td>
    <td class="table-cell table-discount">
      <div class="discount-wrapper">
        <div class="minus" @click="discountHandler('decrement')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M3.33398 8.66665V7.33331H12.6673V8.66665H3.33398Z" fill="#A1BBD9"/>
          </svg>
        </div>
        <input
          v-model="discount"
          type="text"
          @input="validateDiscount"
          @keydown="validateDiscount"
          @blur="editDiscountHandler"
        >
        <div class="plus" @click="discountHandler('increment')">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M7.33398 8.66665H3.33398V7.33331H7.33398V3.33331H8.66732V7.33331H12.6673V8.66665H8.66732V12.6666H7.33398V8.66665Z" fill="#A1BBD9"/>
          </svg>
        </div>
      </div>
    </td>
    <td class="table-cell table-status">
      <div
        ref="statusDropdown"
        class="status-button"
        :class="{
          'new': currentStatus === 0,
          'active': currentStatus === 1,
          'blocked': currentStatus === 2
        }"
        @click.stop="statusDropdownHandler()"
      >
        {{ currentStatus === 0 ? 'New' : currentStatus === 1 ? 'Active' : 'Blocked' }}
      </div>
      <div
        v-if="isOpen"
        class="dropdown"
        :class="{'position-top': positionOfDropdown === 'top'}"
      >
        <div
          class="dropdown-item"
          @click.stop="setStatusHandler(0)"
        >
          <div>
            <span
              class="status-label new"
            >
              N
            </span>
            New
          </div>
          <svg
            v-if="currentStatus === 0"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4068_2939)">
              <path
                d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4068_2939">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="dropdown-item"
          @click.stop="setStatusHandler(1)"
        >
          <div>
            <span
              class="status-label active"
            >
              A
            </span>
            Active
          </div>
          <svg
            v-if="currentStatus === 1"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4068_2939)">
              <path
                d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4068_2939">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="dropdown-item"
          @click.stop="setStatusHandler(2)"
        >
          <div>
            <span
              class="status-label blocked"
            >
              B
            </span>
            Blocked
          </div>
          <svg
            v-if="currentStatus === 2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4068_2939)">
              <path
                d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4068_2939">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';

import UserService from '../../../services/user.service'
import PartnerService from '../../../services/partner.service'
import dayjs from 'dayjs';
import AdminService from '@/services/admin.service';


export default {
  name: "ClientUserListItem",
  components: {

  },
  props: {
    clientUser: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    isNftOpen: {
      type: Boolean,
      required: true
    }
  },
emits: ['change-fav', 'toggle-dropdown', 'close-dropdown'],
  data() {
    return {
      dateFormat: 'DD.MM.YYYY',
      positionOfDropdown: 'bottom',
      discount: null,
      currentDiscount: 0,
      currentStatus: null,
    }
  },
  computed: {
    ...mapGetters({
      UserItem: 'getUserItem',
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.scrollHandler();
    this.discount = this.clientUser.discount + '%'
    this.currentDiscount = this.clientUser.discount
    this.currentStatus = this.clientUser.status

    document.addEventListener('click', this.statusClickOutside);
    document.addEventListener('click', this.nftClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.statusClickOutside);
    document.removeEventListener('click', this.nftClickOutside);
  },
  methods: {
    dateFormatter(value) {
      return dayjs(value).format(this.dateFormat)
    },
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 20) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    dataFormatting(value) {
      return moment(value).format(this.dateFormat);
    },
    web3AddressFormatter(address) {
      const maxLength = address.length
      const valEndSlice = address.length - 8
      return address.slice(0, 8) + '...'+  address.slice(valEndSlice, maxLength)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    editDiscountHandler() {
      const query = {
        discount: +this.discount.replace('%',''),
        status: this.currentStatus,
      }
      return AdminService.editClient(this.clientUser.id,query).then((result) => {
        if (result.status === 200) {
          this.currentDiscount = this.discount
        }
      }).catch((error) => {

      })
    },
    discountHandler(type) {
      const discountNumber = this.discount.replace('%','')
      if (type === 'decrement') {
        this.discount = (+discountNumber - 1) < -100 ? '-100%' : (+discountNumber - 1) + '%'
      } else {
        this.discount = (+discountNumber + 1) > 100 ? '100%' : (+discountNumber + 1) + '%'
      }
      this.editDiscountHandler()
    },
    validateDiscount() {
      if (this.discount === '' || this.discount === '—') {
        return;
      }

      let discountValue = Math.round(Number(this.discount.replace('%','')));

      if (isNaN(discountValue)) {
        discountValue = 0;
      }

      if (discountValue < -100) {
        discountValue = -100;
      } else if (discountValue > 100) {
        discountValue = 100;
      }

      this.discount = discountValue+'%';
    },
    statusDropdownHandler() {
      this.setDropdownPosition()
      this.$emit('toggle-dropdown', this.clientUser.id);
    },
    nftDropdownHandler() {
      this.setDropdownPosition()
      this.$emit('toggle-nft-dropdown', this.clientUser.id);
    },
    setDropdownPosition() {
      const statusDropdown = this.$refs.statusDropdown;
      const rect = statusDropdown.getBoundingClientRect();
      const dropdownHeight = 304;

      if (rect.bottom + dropdownHeight > window.innerHeight) {
        this.positionOfDropdown = 'top';
      } else {
        this.positionOfDropdown = 'bottom';
      }
    },
    statusClickOutside(event) {
      if (this.$refs.statusDropdown && !this.$refs.statusDropdown.contains(event.target)) {
        this.$emit('close-dropdown');
      }
    },
    nftClickOutside(event) {
      if (this.$refs.nftDropdown && !this.$refs.nftDropdown.contains(event.target)) {
        this.$emit('close-nft-dropdown');
      }
    },
    setStatusHandler(status) {
      const query = {
        discount: this.currentDiscount,
        status: status,
      }
      return AdminService.editClient(this.clientUser.id,query).then((result) => {
        if (result.status === 200) {
          this.currentStatus = status
          this.$emit('close-dropdown');
        }
      }).catch((error) => {

      })
    }
  },
}
</script>

<style scoped lang="scss">
.row-color-grey {
  td {
    color: #A1BBD9 !important;
  }

  td.table-id {
    a {
      color: #A1BBD9 !important;
    }
  }

  .email-text {
    color: #A1BBD9 !important;
  }
}

.table-row td {
  height: 40px !important;
}

.table-id {
  padding-left: 32px;
  padding-right: 16px;
  min-width: 40px;
  cursor: pointer;
  >a {
    color: #1B1A1C;
  }
}

.table-createDate {
  padding-right: 16px;
  min-width: 70px;
}

.table-email {
  padding-right: 16px;
  min-width: 160px;
  display: table-cell;
  vertical-align: middle;
}

.table-name {
  word-break: break-all;
  padding-right: 16px;
  min-width: 100px;
}

.table-order {
  padding-right: 16px;
  min-width: 64px;
  text-align: right;
}

.table-nft {
  padding-right: 16px;
  min-width: 24px;

  span {
    display: flex;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: rgba(0, 101, 255, 0.08);
    }
    .dropdown {
      display: flex;
      position: absolute;
      flex-direction: column;
      background: white;
      top: 29px;
      left: 0;
      width: 180px;
      z-index: 999;
      border-radius: 8px;
      padding: 8px 0;
      box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);

      .dropdown-item {
        padding: 6px 12px;
        color: #1A242B;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: #F0F6FF;
        }
      }
    }
  }


}

.email-container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.email-text {
  font-size: 14px;
  color: #333;
}

.table-discount {
  position: relative;
  padding-right: 16px;
  min-width: 76px;

  .discount-wrapper {
    display: inline-flex;
    position: relative;
    height: 24px;
    box-sizing: border-box;

    .minus {
      display: flex;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      transition: .3s;
      cursor: pointer;
    }

    .plus {
      display: flex;
      position: absolute;
      top: 2px;
      right: 2px;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      transition: .3s;
      cursor: pointer;
    }

    .minus:hover,.plus:hover {
      background: rgba(0, 101, 255, 0.08);

      svg path {
        fill: #0065FF;
      }
    }

    input {
      height: 24px;
      width: 76px;
      padding: 2px;
      color: var(--balck, #1A242B);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.28px;
      align-items: center;
      border-radius: 6px;
      background: rgba(0, 101, 255, 0.08);
      box-sizing: border-box;
      border: none;
    }
  }
}

.table-status {
  position: relative;
  min-width: 88px;

  .status-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 88px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.12px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s all;
  }

  .status-button.new {
    background: #A1BBD9;
  }

  .status-button.active {
    background: #02C076;
  }

  .status-button.blocked {
    background: #FF603D;
  }

  .dropdown {
    position: absolute;
    background: white;
    top: 45px;
    right: -6px;
    width: 180px;
    z-index: 999;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);

    @media (max-width: 1079px) {
      top: 41px;
      left: -104px;

      @media (max-width: 819px) {
        top: 10px;
        left: -183px;
      }
    }

    &.position-top {
      top: -105px;
      /*@media (max-width: 1079px) {
        top: -300px;
        @media (max-width: 819px) {
          top: -271px;
        }
      }*/
    }

    .dropdown-item {
      padding: 6px 12px;
      color: #1A242B;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: #F0F6FF;
      }

      .status-label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        width: 18px;
        height: 18px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.12px;
        border-radius: 4px;
      }

      .status-label.new {
        background: #A1BBD9;
      }

      .status-label.active {
        background: #02C076;
      }

      .status-label.blocked {
        background: #FF603D;
      }
    }
  }
}
</style>

<script>
import Toggle from '@vueform/toggle'
import FakeService from "@/services/coinFake.service";
import SearchIcon from "@/icons/searchIcon.vue";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import ClearAllIcon from "@/assets/img/icons/clearAllIcon.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: 'CoinRateCorrectionList',
  components: {ClearAllIcon, SearchIcon, Toggle, simplebar},
  data() {
    return {
      isArchiveList: false,
      showAllRows: [],
      filters: [],
      partnerId: null,
      coinFilter: null,
      isShowPartners: false,
      isShowCoins: false,
      coinSearchValue: '',
      partnerSearchValue: '',
      selectedPartner: null,
      selectedCoin: null,
      coinsList: null,
      partnersList: null,
      partnersListOrigin: null,
      partnersUniqueList: null,
      fakeData: null,
      switcher: true,
      switcherItem1: true,
      switcherItem2: true,
      switcherItem3: true,
      switcherItem4: true,
      switcherItem5: true,
      itemOpen1: true,
      itemOpen2: false,
      currentRowIndex: false,
      currentItemIndex: false,
      expandedElements: [],
      currentGroup: 'partner',
      filterItems: [],
      isFilterPartner: false,
      isFilterCoin: false,
      dateFormatSec: 'DD.MM.YY',
    }
  },
  mounted() {
    this.getFakeByIdRoute()

    this.emitter.on('rate-correction-archive', (status) => {
      this.isArchiveList = status
      this.getFakeData(this.isArchiveList);
    });
    this.getFakeData(this.isArchiveList);
    document.addEventListener('click', this.handleClickOutside);
    this.indexHandler(0)

    this.emitter.on('update-rate-correction', () => {
      this.getFakeData(this.isArchiveList);
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    getFakeData(status) {
      const query = '?isRemoved=' + status
      FakeService.getFakeList(query).then((res) => {
        this.fakeDataOrigin = res.data.result;

        if (this.coinFilter) {
          this.fakeData = this.fakeData.filter(item => item.coinFromName === this.coinFilter.shortName || item.coinToName === this.coinFilter.shortName);
        }

        if (this.partnerId) {
          this.fakeData = this.fakeDataOrigin.filter(item => item.partnerLogin === this.partnerId);
        }

        if (this.currentGroup === 'partner') {
          this.fakeData = this.groupDataByPartnerLogin(this.fakeDataOrigin);
          this.getCoinsListData();
          this.getPartnerList();

        } else {
          this.fakeData = this.groupDataByCoin(this.fakeDataOrigin);
          this.getCoinsListData();
          this.getPartnerList();
        }
      });
    },
    groupDataByPartnerLogin(data) {
      const groupedData = data.reduce((acc, obj) => {
        const {partnerLogin} = obj;
        const existingPartner = acc.find(item => item.partnerLogin === partnerLogin);

        if (existingPartner) {
          existingPartner.items.push(obj);
        } else {
          acc.push({
            partnerLogin,
            items: [obj]
          });
        }

        return acc;
      }, []);

      groupedData.sort((a, b) => {
        const loginA = (a.partnerLogin || 'All partners').toLowerCase();
        const loginB = (b.partnerLogin || 'All partners').toLowerCase();

        if (loginA < loginB) {
          return -1;
        }
        if (loginA > loginB) {
          return 1;
        }
        return 0;
      });

      return groupedData;
    },
    groupDataByCoin(data) {
      const groupedData = data.reduce((acc, obj) => {
        const { coinFromName, coinToName } = obj;

        if (!acc[coinFromName]) {
          acc[coinFromName] = {
            coin: coinFromName,
            items: [obj],
          };
        } else {
          acc[coinFromName].items.push(obj);
        }

        if (!acc[coinToName]) {
          acc[coinToName] = {
            coin: coinToName,
            items: [obj],
          };
        } else {
          acc[coinToName].items.push(obj);
        }

        return acc;
      }, {});

      const values = Object.values(groupedData);

      values.sort((a, b) => {
        const coinA = (a.coin || '').toLowerCase();
        const coinB = (b.coin || '').toLowerCase();

        if (coinA < coinB) {
          return -1;
        }
        if (coinA > coinB) {
          return 1;
        }
        return 0;
      });

      return values;
    },

    getFakeByIdRoute() {
      const id = this.$route.params.id
      if (id) {
        FakeService.getFakeById(id).then((res) => {
          this.$router.push({name: 'Coin Rate correction edit', params: {id: id}})
          this.emitter.emit('edit-correction', res.data.result);
        })
      } else {
        this.$router.push({name: 'Coin Rate correction'});
        this.emitter.emit('edit-correction', null);
      }
    },
    getPartnerList() {
      this.partnersList = this.groupDataByPartnerLogin(this.fakeDataOrigin);
      this.partnersListOrigin = this.groupDataByPartnerLogin(this.fakeDataOrigin);
    },
    getFakeById(id) {
      FakeService.getFakeById(id).then((res) => {
        this.$router.push({name: 'Coin Rate correction edit', params: {id: id}})
        this.emitter.emit('edit-correction', res.data.result);
      })
    },
    getCoinsListData() {
      this.coinsList = this.groupDataByCoin(this.fakeDataOrigin);
      this.coinsListOrigin = this.groupDataByCoin(this.fakeDataOrigin);

    },
    async updateFakeStatus(id, status) {
      const query = '?state=' + status;

      let statusText = '';

      if (status === false) {
        statusText = 'deactivated'
      } else {
        statusText = 'activated'
      }

      this.setNotification({name: 'Item ID:' + id + ' ' + statusText});

      setTimeout(async () => {
        if (this.$store.state.notification?.name) {
          await FakeService.updateFakeStatusById(id, query).then((res) => {
            if (res.data.result) {
              this.setNotification({});
              this.getFakeData(this.isArchiveList)
            }
          }).catch(() => {
            this.setNotification({name: 'Error', value: 'status: '});
          })
        } else {
          this.getFakeData(this.isArchiveList);
        }
      }, 3000);

    },
    setNewGroup(type) {
      this.currentGroup = type;

      this.isFilterPartner = false;
      this.isFilterCoin = false;
      this.partnerId = null;
      this.coinFilter = null;

      if (this.currentGroup === 'partner') {
        this.fakeData = this.groupDataByPartnerLogin(this.fakeDataOrigin);
      } else {
        this.fakeData = this.groupDataByCoin(this.fakeDataOrigin);
      }
    },
    searchCoin(el) {
      const value = el.target.value.toLowerCase();

      this.coinsList = this.coinsListOrigin;

      if (value && value.length) {
        this.coinsList = this.coinsList.filter((item) => {
          return item.coin.toLowerCase().indexOf(value) > -1
        });
      } else {
        this.isSearch = false
      }
    },
    createNew() {
      this.$router.push({name: 'Coin Rate correction create'})
      this.emitter.emit('create-new-correction', true);
    },
    async updateRowStatusById(id, status) {
      const query = '?state=' + status

      let statusText = '';

      if (status === false) {
        statusText = 'deactivated'
      } else {
        statusText = 'activated'
      }

      this.setNotification({name: 'Rule ID:' + id + ' ' + statusText});

      setTimeout(async () => {
        if (this.$store.state.notification?.name) {
          await FakeService.updateRowStatusById(id, query).then((res) => {
            if (res.data.result) {
              this.setNotification({});
              this.getFakeData(this.isArchiveList)
            }
          }).catch(() => {
            this.setNotification({name: 'Error', value: 'status: '});
          })
        } else {
          this.getFakeData(this.isArchiveList);
        }
      }, 3000);
    },
    partnerStatusHandler(status, data) {

      data.forEach((item) => {

        this.updateFakeStatus(item.id, !status);

      });
      this.getFakeData(this.isArchiveList)
    },
    checkIfAnyActive(items) {
      if (!items || !Array.isArray(items) || items.length === 0) {
        return false;
      }

      return items.some(item => item.isActive === true);
    },
    searchPartnerHandlerButton(el) {
      this.partnerId = el;
      this.isFilterPartner = true;
      this.fakeData = this.fakeDataOrigin.filter(item => item.partnerLogin === el);

      if (this.isFilterCoin) {
        this.fakeData = this.fakeData.filter(item => item.coinFromName === this.coinFilter || item.coinToName === this.coinFilter);
      }

      if (this.currentGroup === 'partner') {
        this.fakeData = this.groupDataByPartnerLogin(this.fakeData);
      } else {
        this.fakeData = this.groupDataByCoin(this.fakeData);
      }
      el = '';
      this.isShowPartners = false;
    },
    searchCoinHandlerButton(el) {
      this.coinFilter = el;
      this.isFilterCoin = true;
      this.fakeData = this.fakeDataOrigin.filter(item => item.coinFromName === el || item.coinToName === el);

      if (this.isFilterPartner) {
        this.fakeData = this.fakeData.filter(item => item.partnerLogin === this.partnerId);
      }

      if (this.currentGroup === 'partner') {
        this.fakeData = this.groupDataByPartnerLogin(this.fakeData);
      } else {
        this.fakeData = this.groupDataByCoin(this.fakeData);

      }
      el = '';
      this.isShowCoins = false;
      this.coinSearchValue = '';
      this.coinsList = this.coinsListOrigin;
    },
    searchPartnerHandler(el) {
      const value = el.target.value.toLowerCase();

      if (el.inputType === 'deleteContentBackward' && value.length === 0) {
        this.partnersList = this.partnersListOrigin;
        return;
      }

      if (value.length) {
        this.partnersList = this.partnersListOrigin.filter(partner =>
            partner.partnerLogin.toLowerCase().indexOf(value) > -1
        );
      } else {
        this.partnersList = this.partnersListOrigin;
      }
    },
    archiveItem(id, status) {
      const query = '?state=' + status;

      let statusText = '';

      if (status === false) {
        statusText = 'Archived'
      } else {
        statusText = 'Unarchived'
      }

      this.setNotification({name: 'Item ID:' + id, value: statusText});

      setTimeout(async () => {
        if (this.$store.state.notification?.name) {
          await FakeService.archiveFake(id, query).then((res) => {
            if (res.data.result) {
              this.setNotification({});
              this.getFakeData(this.isArchiveList)
            }
          }).catch(() => {
            this.setNotification({name: 'Error', value: 'status: '});
          })
        } else {
          this.getFakeData(this.isArchiveList);
        }
      }, 3000);
    },
    shouldShowAll(index) {
      return this.expandedElements.includes(index);
    },
    closeAllFilters() {
      this.partnerId = null;
      this.coinFilter = null;

      this.isFilterPartner = false;
      this.isFilterCoin = false;

      this.getFakeData(this.isArchiveList);
    },
    partnerHandler() {
      this.partnerId = null;
      this.isFilterPartner = false;

      if (!this.isFilterCoin) {
        this.getFakeData(this.isArchiveList);
      } else {
        this.searchCoinHandlerButton(this.coinFilter)
      }
    },
    coinHandler() {
      this.coinFilter = null;
      this.isFilterCoin = false;

      if (!this.isFilterPartner) {
        this.getFakeData(this.isArchiveList);
      } else {
        this.searchPartnerHandlerButton(this.partnerId)
      }
    },
    indexHandler(index) {
      this.currentItemIndex = this.currentItemIndex === index ? false : index;
      this.expandedElements = []
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatSec)
    },
    handleClickOutside(event) {
      if (this.$refs.partners && !this.$refs.partners.contains(event.target) && this.isShowPartners && this.$refs.partnersDrop && !this.$refs.partnersDrop.contains(event.target)) {
        this.isShowPartners = false;
      }

      if (this.$refs.coins && !this.$refs.coins.contains(event.target) && this.isShowCoins && this.$refs.coinsDrop && !this.$refs.coinsDrop.contains(event.target)) {
        this.isShowCoins = false;
      }

      if (this.$refs.coinFrom && !this.$refs.coinFrom.contains(event.target)) {
        this.isShowCoinsFrom = false;
      }
    },
    toggleElement(index) {
      if (this.expandedElements.includes(index)) {
        this.expandedElements = this.expandedElements.filter((item) => item !== index);

      } else {
        this.expandedElements.push(index);
      }
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);

    }
  }
}
</script>

<template>
  <div class="rate-correction main-block">
    <div class="header">
      <div class="title">
        Correction by partners
      </div>
      <div class="ctrl-panel">
        <div class="filters">
          <div class="switcher">
            <div
              class="item"
              :class="{'item--active': currentGroup === 'partner'}"
              @click="setNewGroup('partner')"
            >
              By partners
            </div>
            <div
              class="item"
              :class="{'item--active': currentGroup === 'coin'}"
              @click="setNewGroup('coin')"
            >
              By coins
            </div>
          </div>
          <div class="divider">
            <svg
              width="1"
              height="20"
              viewBox="0 0 1 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="20"
                height="1"
                rx="0.5"
                transform="matrix(0 1 1 0 0 0)"
                fill="#DFE0EB"
              />
            </svg>
          </div>
          <div class="filters_buttons">
            <div class="filters_buttons--item">
              <div
                ref="partners"
                class="item"
                @click="isShowPartners = !isShowPartners"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4241_71743)">
                    <path
                      d="M10.0013 1.66699C5.4013 1.66699 1.66797 5.40033 1.66797 10.0003C1.66797 14.6003 5.4013 18.3337 10.0013 18.3337C14.6013 18.3337 18.3346 14.6003 18.3346 10.0003C18.3346 5.40033 14.6013 1.66699 10.0013 1.66699ZM6.1263 15.417C7.21797 14.6337 8.5513 14.167 10.0013 14.167C11.4513 14.167 12.7846 14.6337 13.8763 15.417C12.7846 16.2003 11.4513 16.667 10.0013 16.667C8.5513 16.667 7.21797 16.2003 6.1263 15.417ZM15.118 14.267C13.7096 13.167 11.9346 12.5003 10.0013 12.5003C8.06797 12.5003 6.29297 13.167 4.88464 14.267C3.91797 13.1087 3.33464 11.6253 3.33464 10.0003C3.33464 6.31699 6.31797 3.33366 10.0013 3.33366C13.6846 3.33366 16.668 6.31699 16.668 10.0003C16.668 11.6253 16.0846 13.1087 15.118 14.267Z"
                      fill="#0A68F7"
                    />
                    <path
                      d="M10.0026 5C8.39427 5 7.08594 6.30833 7.08594 7.91667C7.08594 9.525 8.39427 10.8333 10.0026 10.8333C11.6109 10.8333 12.9193 9.525 12.9193 7.91667C12.9193 6.30833 11.6109 5 10.0026 5ZM10.0026 9.16667C9.31094 9.16667 8.7526 8.60833 8.7526 7.91667C8.7526 7.225 9.31094 6.66667 10.0026 6.66667C10.6943 6.66667 11.2526 7.225 11.2526 7.91667C11.2526 8.60833 10.6943 9.16667 10.0026 9.16667Z"
                      fill="#0A68F7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4241_71743">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                Partner login
              </div>

              <div
                v-if="isShowPartners"
                ref="partnersDrop"
                class="dropdown"
              >
                <div class="search">
                  <search-icon />
                  <input
                    v-model="partnerSearchValue"
                    type="text"
                    placeholder="Login search"
                    @input="searchPartnerHandler"
                    @keyup.enter="searchPartnerHandler"
                  >
                </div>
                <simplebar
                  class="dropdown_list"
                  data-simplebar-auto-hide="false"
                >
                  <div
                    v-for="(item, index) in partnersList"
                    :key="index"
                    class="dropdown_list--item"
                    @click="searchPartnerHandlerButton(item.partnerLogin)"
                  >
                    {{ item.partnerLogin }}
                  </div>
                </simplebar>
              </div>
            </div>
            <div class="filters_buttons--item">
              <div
                ref="coins"
                class="item"
                @click="isShowCoins = !isShowCoins"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4241_71752)">
                    <path
                      d="M5.83333 9.16699H7.5V10.8337H5.83333V9.16699ZM17.5 5.00033V16.667C17.5 17.5837 16.75 18.3337 15.8333 18.3337H4.16667C3.24167 18.3337 2.5 17.5837 2.5 16.667L2.50833 5.00033C2.50833 4.08366 3.24167 3.33366 4.16667 3.33366H5V1.66699H6.66667V3.33366H13.3333V1.66699H15V3.33366H15.8333C16.75 3.33366 17.5 4.08366 17.5 5.00033ZM4.16667 6.66699H15.8333V5.00033H4.16667V6.66699ZM15.8333 16.667V8.33366H4.16667V16.667H15.8333ZM12.5 10.8337H14.1667V9.16699H12.5V10.8337ZM9.16667 10.8337H10.8333V9.16699H9.16667V10.8337Z"
                      fill="#0A68F7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4241_71752">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                Coins
              </div>
              <div
                v-if="isShowCoins"
                ref="coinsDrop"
                class="dropdown"
              >
                <div class="search">
                  <search-icon />
                  <input
                    v-model="coinSearchValue"
                    type="text"
                    placeholder="Coin search"
                    @input="searchCoin"
                    @keyup.enter="searchCoin"
                  >
                </div>
                <simplebar
                  class="dropdown_list"
                  data-simplebar-auto-hide="false"
                >
                  <div
                    v-for="(item, index) in coinsList"
                    :key="index"
                    class="dropdown_list--item"
                    @click="searchCoinHandlerButton(item.coin)"
                  >
                    {{ item.coin }}
                  </div>
                </simplebar>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <div
            class="create"
            @click="createNew()"
          >
            Create new correction
          </div>
          <!--          <div class="other">-->
          <!--            <svg-->
          <!--              width="20"-->
          <!--              height="20"-->
          <!--              viewBox="0 0 20 20"-->
          <!--              fill="none"-->
          <!--              xmlns="http://www.w3.org/2000/svg"-->
          <!--            >-->
          <!--              <path-->
          <!--                d="M9.9987 16.6663C9.54036 16.6663 9.148 16.5031 8.82161 16.1768C8.49523 15.8504 8.33203 15.458 8.33203 14.9997C8.33203 14.5413 8.49523 14.149 8.82161 13.8226C9.148 13.4962 9.54036 13.333 9.9987 13.333C10.457 13.333 10.8494 13.4962 11.1758 13.8226C11.5022 14.149 11.6654 14.5413 11.6654 14.9997C11.6654 15.458 11.5022 15.8504 11.1758 16.1768C10.8494 16.5031 10.457 16.6663 9.9987 16.6663ZM9.9987 11.6663C9.54036 11.6663 9.148 11.5031 8.82161 11.1768C8.49523 10.8504 8.33203 10.458 8.33203 9.99967C8.33203 9.54134 8.49523 9.14898 8.82161 8.82259C9.148 8.4962 9.54036 8.33301 9.9987 8.33301C10.457 8.33301 10.8494 8.4962 11.1758 8.82259C11.5022 9.14898 11.6654 9.54134 11.6654 9.99967C11.6654 10.458 11.5022 10.8504 11.1758 11.1768C10.8494 11.5031 10.457 11.6663 9.9987 11.6663ZM9.9987 6.66634C9.54036 6.66634 9.148 6.50315 8.82161 6.17676C8.49523 5.85037 8.33203 5.45801 8.33203 4.99967C8.33203 4.54134 8.49523 4.14898 8.82161 3.82259C9.148 3.4962 9.54036 3.33301 9.9987 3.33301C10.457 3.33301 10.8494 3.4962 11.1758 3.82259C11.5022 4.14898 11.6654 4.54134 11.6654 4.99967C11.6654 5.45801 11.5022 5.85037 11.1758 6.17676C10.8494 6.50315 10.457 6.66634 9.9987 6.66634Z"-->
          <!--                fill="#0A68F7"-->
          <!--              />-->
          <!--            </svg>-->
          <!--          </div>-->
        </div>
      </div>
      <div
        v-if="partnerId || coinFilter"
        class="filtersBlock"
      >
        <div v-if="partnerId">
          <div
            class="filters-selected"
          >
            <span>Partner login: </span> {{ partnerId }}
            <div class="filters-selected-close">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="partnerHandler()"
              >
                <g clip-path="url(#clip0_4300_16665)">
                  <path
                    d="M15.2496 4.75794C14.9246 4.43294 14.3996 4.43294 14.0746 4.75794L9.99961 8.82461L5.92461 4.74961C5.59961 4.42461 5.07461 4.42461 4.74961 4.74961C4.42461 5.07461 4.42461 5.59961 4.74961 5.92461L8.82461 9.99961L4.74961 14.0746C4.42461 14.3996 4.42461 14.9246 4.74961 15.2496C5.07461 15.5746 5.59961 15.5746 5.92461 15.2496L9.99961 11.1746L14.0746 15.2496C14.3996 15.5746 14.9246 15.5746 15.2496 15.2496C15.5746 14.9246 15.5746 14.3996 15.2496 14.0746L11.1746 9.99961L15.2496 5.92461C15.5663 5.60794 15.5663 5.07461 15.2496 4.75794Z"
                    fill="#9FA2B4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4300_16665">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <div v-if="coinFilter">
          <div
            class="filters-selected"
          >
            <span>Coin: </span> {{ coinFilter }}
            <div class="filters-selected-close">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="coinHandler()"
              >
                <g clip-path="url(#clip0_4300_16665)">
                  <path
                    d="M15.2496 4.75794C14.9246 4.43294 14.3996 4.43294 14.0746 4.75794L9.99961 8.82461L5.92461 4.74961C5.59961 4.42461 5.07461 4.42461 4.74961 4.74961C4.42461 5.07461 4.42461 5.59961 4.74961 5.92461L8.82461 9.99961L4.74961 14.0746C4.42461 14.3996 4.42461 14.9246 4.74961 15.2496C5.07461 15.5746 5.59961 15.5746 5.92461 15.2496L9.99961 11.1746L14.0746 15.2496C14.3996 15.5746 14.9246 15.5746 15.2496 15.2496C15.5746 14.9246 15.5746 14.3996 15.2496 14.0746L11.1746 9.99961L15.2496 5.92461C15.5663 5.60794 15.5663 5.07461 15.2496 4.75794Z"
                    fill="#9FA2B4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4300_16665">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <div
          v-if="partnerId || coinFilter"
          class="clear-all"
          @click="closeAllFilters"
        >
          <clear-all-icon />
          Clear all
        </div>
      </div>
    </div>
    <div class="container">
      <div class="corrections">
        <div
          v-for="(partner, index) in fakeData"
          :key="index"
        >
          <div
            class="corrections__item"
            :class="{'corrections__item--active': currentItemIndex === index}"
          >
            <div class="head">
              <div
                v-if="currentGroup === 'partner'"
                class="title"
                @click="indexHandler(index)"
              >
                <div
                  v-if="!partner.partnerLogin"
                  class="title-text"
                >
                  All partners
                </div>
                <div
                  v-else
                  class="title-text"
                >
                  {{ partner.partnerLogin }}
                </div>
                <div
                  v-if="partner.items"
                  class="num"
                >
                  {{ partner.items.length }}
                </div>
              </div>
              <div
                v-if="currentGroup === 'coin'"
                class="title"
                @click="indexHandler(index)"
              >
                <div
                  class="title-text"
                >
                  {{ partner.coin }}
                </div>
                <div
                  v-if="partner.items"
                  class="num"
                >
                  {{ partner.items.length }}
                </div>
              </div>
              <div class="buttons">
                <div class="switcher">
                  <Toggle
                    :value="checkIfAnyActive(partner.items)"
                    @change="partnerStatusHandler(checkIfAnyActive(partner.items), partner.items)"
                  />
                </div>
                <div
                  class="more"
                  @click="indexHandler(index)"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4241_71666)">
                      <path
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                        fill="#9FA2B4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4241_71666">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div
              v-if="currentItemIndex === index"
              class="corrections_list"
            >
              <div
                v-for="(item, id) in partner.items"
                :key="id"
                class="rate_item"
                :class="{'rate_item-disabled': !item.isActive}"
              >
                <div
                  class="main"
                >
                  <div
                    class="name"
                    @click="getFakeById(item.id)"
                  >
                    <div
                      v-if="item.coinType === 'pair'"
                      class="title"
                    >
                      {{ item.coinFromName }}/{{ item.coinToName }}
                    </div>
                    <div
                      v-else
                      class="title"
                    >
                      {{ item.coinFromName }}
                    </div>
                    <div
                      v-if="item.partnerLogin"
                      class="desc"
                    >
                      {{ item.partnerLogin }}
                    </div>
                    <div
                      v-if="!item.partnerLogin"
                      class="desc"
                    >
                      All partners
                    </div>
                  </div>
                  <div class="buttons">
                    <div class="buttons-archive">
                      <svg
                        v-if="item.isRemove"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="archiveItem(item.id, false); item.isRemove = !item.isRemove"
                      >
                        <path
                          d="M11 14V25H25V14H11ZM11 27C10.45 27 9.97917 26.8042 9.5875 26.4125C9.19583 26.0208 9 25.55 9 25V12.525C9 12.2917 9.0375 12.0667 9.1125 11.85C9.1875 11.6333 9.3 11.4333 9.45 11.25L10.7 9.725C10.8833 9.49167 11.1125 9.3125 11.3875 9.1875C11.6625 9.0625 11.95 9 12.25 9H23.75C24.05 9 24.3375 9.0625 24.6125 9.1875C24.8875 9.3125 25.1167 9.49167 25.3 9.725L26.55 11.25C26.7 11.4333 26.8125 11.6333 26.8875 11.85C26.9625 12.0667 27 12.2917 27 12.525V25C27 25.55 26.8042 26.0208 26.4125 26.4125C26.0208 26.8042 25.55 27 25 27H11ZM11.4 12H24.6L23.75 11H12.25L11.4 12Z"
                          fill="#DFE0EB"
                        />
                        <path
                          d="M14 19L18 15L22 19L20.6 20.4L19 18.8V23H17V18.8L15.4 20.4L14 19Z"
                          fill="#DFE0EB"
                        />
                      </svg>

                      <svg
                        v-else
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="archiveItem(item.id,true); item.isRemove = !item.isRemove"
                      >
                        <path
                          d="M18 24L22 20L20.6 18.6L19 20.2V16H17V20.2L15.4 18.6L14 20L18 24ZM11 14V25H25V14H11ZM11 27C10.45 27 9.97917 26.8042 9.5875 26.4125C9.19583 26.0208 9 25.55 9 25V12.525C9 12.2917 9.0375 12.0667 9.1125 11.85C9.1875 11.6333 9.3 11.4333 9.45 11.25L10.7 9.725C10.8833 9.49167 11.1125 9.3125 11.3875 9.1875C11.6625 9.0625 11.95 9 12.25 9H23.75C24.05 9 24.3375 9.0625 24.6125 9.1875C24.8875 9.3125 25.1167 9.49167 25.3 9.725L26.55 11.25C26.7 11.4333 26.8125 11.6333 26.8875 11.85C26.9625 12.0667 27 12.2917 27 12.525V25C27 25.55 26.8042 26.0208 26.4125 26.4125C26.0208 26.8042 25.55 27 25 27H11ZM11.4 12H24.6L23.75 11H12.25L11.4 12Z"
                          fill="#DFE0EB"
                        />
                      </svg>
                    </div>

                    <div class="buttons-toggle">
                      <Toggle
                        v-model="item.isActive"
                        @change="updateFakeStatus(item.id, item.isActive)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.rows && item.rows.length"
                  class="rate_item-list"
                >
                  <div class="rate_item-list_block">
                    <div
                      v-for="(row, indx) in item.rows.slice(0, shouldShowAll(id) ? item.rows.length : 2)"
                      :key="indx"
                      class="rate_item-list--item"
                      :class="{'rate_item-list--item_disabled': !row.isActive}"
                    >
                      <div class="info">
                        <div
                          class="num"
                          :class="{'num-down': row.percent < 0 && item.isActive && row.isActive, 'num-up': row.percent > 0 && item.isActive && row.isActive}"
                        >
                          {{ row.percent.toFixed(2) }}%
                        </div>
                        <div class="text">
                          <span>
                            {{ row.minAmount }} - {{ row.maxAmount }} {{ item.coinFromName }}
                          </span>
                          <span>
                            {{ row.authorLogin }} - {{ dataFormatting(partner.updatedAt) }}
                          </span>
                        </div>
                      </div>
                      <Toggle
                        v-model="row.isActive"
                        @change="updateRowStatusById(row.id, row.isActive)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="item.rows && item.rows.length > 2 && !currentRowIndex && !expandedElements.length"
                    class="show-more"
                    @click="toggleElement(id)"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 12.8122L5 7.81217L6.16667 6.64551L10 10.4788L13.8333 6.64551L15 7.81217L10 12.8122Z"
                        fill="#0A68F7"
                      />
                    </svg>
                    Show more ({{ item.rows.length }})
                  </div>
                  <div
                    v-if="item.rows && item.rows.length > 2 && expandedElements.length"
                    class="show-more"
                    @click="toggleElement(id)"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 12.8122L5 7.81217L6.16667 6.64551L10 10.4788L13.8333 6.64551L15 7.81217L10 12.8122Z"
                        fill="#0A68F7"
                      />
                    </svg>
                    Show less
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

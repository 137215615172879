<template>
  <div class="nodata">
    <Vue3Lottie
      :animation-data="ServerError"
      :loop="true"
      :height="100"
      :width="100"
    />
    <img
      src="../../assets/spiderweb.json"
      alt=""
    >
    <div class="title">
      Server Error
    </div>
    <div class="desc">
      Apparently this data is not available at all. <br>
      Or you don't have a connection. Try to update
    </div>
    <button
      class="update-button"
      @click="reloadPage"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clip-path="url(#clip0_5654_1598)">
          <path
            d="M17.5 8.33333V2.5L15.3 4.7C13.95 3.34167 12.075 2.5 10 2.5C5.85833 2.5 2.5 5.85833 2.5 10C2.5 14.1417 5.85833 17.5 10 17.5C14.1417 17.5 17.5 14.1417 17.5 10H15.8333C15.8333 13.2167 13.2167 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 6.78333 6.78333 4.16667 10 4.16667C11.6083 4.16667 13.0667 4.825 14.125 5.875L11.6667 8.33333H17.5Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_5654_1598">
            <rect
              width="20"
              height="20"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>

      Update
    </button>
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import ServerError from '../../assets/server-error.json'
export default {
  name: "ServerErrorBlock",
  components: {Vue3Lottie},
  data() {
    return {
      ServerError
    }
  },
  methods: {
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style scoped lang="scss">
  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    align-items: center;
    padding-top: 39px;
    padding-bottom: 120px;
    box-sizing: border-box;

    .title {
      margin-top: 20px;
      max-width: 350px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #1B1A1C;
      margin-bottom: 12px;
    }

    .desc {
      max-width: 350px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #1B1A1C;
    }

    .update-button {
      display: inline-flex;
      margin-top: 24px;
      padding: 10px 16px;
      gap: 8px;
      align-items: flex-start;
      border-radius: 8px;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      background: #FF5F3C;
      border: none;
      cursor: pointer;
    }
  }
</style>
